import React, { useEffect, useState, useRef } from "react";

type Props = {
  id?: string;
  pogrId?: string;
  children?: React.ReactNode;
  style?: React.CSSProperties;
  onChange: (visible: boolean) => void;
};

function IntersectionObserverWrapper({
  id,
  pogrId,
  children,
  style,
  onChange,
}: Props) {
  const [isVisible, setVisible] = useState<boolean>(false);
  const domRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => setVisible(entry.isIntersecting));
    });
    observer.observe(domRef.current as any);
  }, []);

  useEffect(() => {
    onChange(isVisible);
  }, [isVisible]);

  return (
    <div
      id={`${id}`}
      data-pogr-id={`${pogrId || id}`}
      style={style}
      ref={domRef}
    >
      {children}
    </div>
  );
}

export default IntersectionObserverWrapper;
