const MS_IN_HOUR = 3600000;
const MS_IN_MIN = 60000;

const convertMilliseconds = (time: number, type: 'ms' | 's' | 'min' = 'ms') => {
  const hours = Math.floor(time / MS_IN_HOUR);
  const minutes = Math.floor((time % MS_IN_HOUR) / MS_IN_MIN);
  const seconds = Math.floor((time % MS_IN_MIN) / 1000);
  const timeString = `${hours > 0 ? `${hours} hr` : ''} ${minutes > 0 ? `${minutes} min` : ''} ${seconds > 0 ? `${seconds} sec` : ''}`;

  return {
    hours,
    minutes,
    seconds,
    timeString,
  };
}

export default convertMilliseconds;