import { useSpring, config, animated } from "react-spring";
import CareerDetailsHeroImage from "../../../components/layouts/hero-image/CareerDetailsHeroImage";
import Section from "../../../components/layouts/section/Section";
import styles from "../HeroImageSection.module.scss";
import careers from "../../careers-openings-list-section/careers-dummy-data.json";
import { useEffect, useState } from "react";
import { CareerType } from "../../../types/career";

type Props = {
  openingId?: string;
};

const ID_PREFIX = "career-details-hero-image-section";

function CareerDetailsHeroImageSection({ openingId }: Props) {
  const [currentOpening, setCurrentOpening] = useState<CareerType | null>(null);

  const animTitle = useSpring({
    from: {
      opacity: 0,
      y: 200,
    },
    to: {
      opacity: 1,
      y: 0,
    },
    delay: 200,
    config: config.default,
  });

  const animDescription = useSpring({
    from: {
      opacity: 0,
      y: 200,
    },
    to: {
      opacity: 1,
      y: 0,
    },
    delay: 400,
    config: config.default,
  });

  useEffect(() => {
    if (openingId) {
      let openingWithId = (careers as CareerType[]).filter(
        (opening) => opening.id === openingId
      );
      setCurrentOpening(openingWithId[0]);
    }
  }, []);

  return (
    <Section id={`${ID_PREFIX}-main`} pogrId={`${ID_PREFIX}-main`}>
      <CareerDetailsHeroImage />
      <div
        id={`${ID_PREFIX}-text-overlay`}
        data-pogr-id={`${ID_PREFIX}-text-overlay`}
        className={styles.textOverlayForCareers}
      >
        <div
          id={`${ID_PREFIX}-content`}
          data-pogr-id={`${ID_PREFIX}-content`}
          className={styles.content}
        >
          <animated.h1
            id={`${ID_PREFIX}-title-animated`}
            data-pogr-id={`${ID_PREFIX}-title-animated`}
            style={animTitle}
            className={styles.title}
          >
            {currentOpening?.title}
          </animated.h1>
          <animated.div
            id={`${ID_PREFIX}-description-animated`}
            data-pogr-id={`${ID_PREFIX}-description-animated`}
            style={animDescription}
            className={styles.openingDetailsTypeLocation}
          >
            <div
              id={`${ID_PREFIX}-opening-item-${currentOpening?.id}-job_type`}
              data-pogr-id={`${ID_PREFIX}-opening-item-${currentOpening?.id}-job_type`}
              className={styles.openingDetails}
            >
              <img
                src={"/static/clock.svg"}
                width="20"
                height="20"
                alt="Clock Icon for Job Type"
              />
              {currentOpening?.job_type == "part-time"
                ? "Part-time"
                : "Full-time"}
            </div>
            <div
              id={`${ID_PREFIX}-opening-item-${currentOpening?.id}-location`}
              data-pogr-id={`${ID_PREFIX}-opening-item-${currentOpening?.id}-location`}
              className={styles.openingDetails}
            >
              <img
                src={"/static/world.svg"}
                width="20"
                height="20"
                alt="World Icon for position"
              />
              {currentOpening?.location}
            </div>
          </animated.div>
        </div>
      </div>
    </Section>
  );
}

export default CareerDetailsHeroImageSection;
