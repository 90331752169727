import React from "react";
import { Element } from "react-scroll";
import Section from "../../components/layouts/section/Section";
import WaitlistForm from "../../modules/waitlist-form/WaitlistForm";
import styles from "./WaitlistSection.module.scss";

type Props = {
  isDev?: boolean;
};

const ID_PREFIX = "waitlist-section";

function WaitlistSection({ isDev }: Props) {
  return (
    <Element
      name="waitlist"
      id={`${ID_PREFIX}-scroll-element`}
      data-pogr-id={`${ID_PREFIX}-scroll-element`}
    >
      <Section id={`${ID_PREFIX}-main`} pogrId={`${ID_PREFIX}-main`}>
        <div
          id={`${ID_PREFIX}-container`}
          data-pogr-id={`${ID_PREFIX}-container`}
          className={styles.container}
          style={{ backgroundImage: `url("/static/waitlist-bg.webp")` }}
        >
          <WaitlistForm initialPage={isDev} />
          <div
            id={`${ID_PREFIX}-top-gradient`}
            data-pogr-id={`${ID_PREFIX}-top-gradient`}
            className={styles.topGradient}
          />
          <div
            id={`${ID_PREFIX}-bottom-gradient`}
            data-pogr-id={`${ID_PREFIX}-bottom-gradient`}
            className={styles.bottomGradient}
          />
        </div>
      </Section>
    </Element>
  );
}

export default WaitlistSection;
