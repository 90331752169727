import React, { useState } from "react";
import styles from "./PlatformSection.module.scss";
import Section from "../../components/layouts/section/Section";
import SupportIcon from "../../modules/support-icon/SupportIcon";
import IntersectionObserverWrapper from "../../components/intersection-observer-wrapper/IntersectionObserverWrapper";
import { useSpring, config, animated } from "react-spring";

type Props = {};

const ID_PREFIX = "platform-section";

function PlatformSection({}: Props) {
  const [isVisible, setIsVisible] = useState(false);
  const animTitle = useSpring({
    from: {
      opacity: 0,
      x: 400,
    },
    to: {
      opacity: isVisible ? 1 : 0,
      x: isVisible ? 0 : 400,
    },
    delay: 200,
    reset: isVisible ? false : true,
    config: config.default,
  });
  const animXbox = useSpring({
    from: {
      opacity: 0,
      scale: 0,
    },
    to: {
      opacity: isVisible ? 1 : 0,
      scale: isVisible ? 1 : 0,
    },
    delay: 500,
    reset: isVisible ? false : true,
    config: config.default,
  });
  const animPlaystation = useSpring({
    from: {
      opacity: 0,
      scale: 0,
    },
    to: {
      opacity: isVisible ? 1 : 0,
      scale: isVisible ? 1 : 0,
    },
    delay: 600,
    reset: isVisible ? false : true,
    config: config.default,
  });
  const animWindows = useSpring({
    from: {
      opacity: 0,
      scale: 0,
    },
    to: {
      opacity: isVisible ? 1 : 0,
      scale: isVisible ? 1 : 0,
    },
    delay: 700,
    reset: isVisible ? false : true,
    config: config.default,
  });
  const animNintendo = useSpring({
    from: {
      opacity: 0,
      scale: 0,
    },
    to: {
      opacity: isVisible ? 1 : 0,
      scale: isVisible ? 1 : 0,
    },
    delay: 800,
    reset: isVisible ? false : true,
    config: config.default,
  });
  const animIos = useSpring({
    from: {
      opacity: 0,
      scale: 0,
    },
    to: {
      opacity: isVisible ? 1 : 0,
      scale: isVisible ? 1 : 0,
    },
    delay: 900,
    reset: isVisible ? false : true,
    config: config.default,
  });
  const animMacos = useSpring({
    from: {
      opacity: 0,
      scale: 0,
    },
    to: {
      opacity: isVisible ? 1 : 0,
      scale: isVisible ? 1 : 0,
    },
    delay: 1000,
    reset: isVisible ? false : true,
    config: config.default,
  });
  const animAndroid = useSpring({
    from: {
      opacity: 0,
      scale: 0,
    },
    to: {
      opacity: isVisible ? 1 : 0,
      scale: isVisible ? 1 : 0,
    },
    delay: 1100,
    reset: isVisible ? false : true,
    config: config.default,
  });
  const animLinux = useSpring({
    from: {
      opacity: 0,
      scale: 0,
    },
    to: {
      opacity: isVisible ? 1 : 0,
      scale: isVisible ? 1 : 0,
    },
    delay: 1200,
    reset: isVisible ? false : true,
    config: config.default,
  });
  const animSteam = useSpring({
    from: {
      opacity: 0,
      scale: 0,
    },
    to: {
      opacity: isVisible ? 1 : 0,
      scale: isVisible ? 1 : 0,
    },
    delay: 1300,
    reset: isVisible ? false : true,
    config: config.default,
  });
  const animKube = useSpring({
    from: {
      opacity: 0,
      scale: 0,
    },
    to: {
      opacity: isVisible ? 1 : 0,
      scale: isVisible ? 1 : 0,
    },
    delay: 1400,
    reset: isVisible ? false : true,
    config: config.default,
  });
  const animGcp = useSpring({
    from: {
      opacity: 0,
      scale: 0,
    },
    to: {
      opacity: isVisible ? 1 : 0,
      scale: isVisible ? 1 : 0,
    },
    delay: 1500,
    reset: isVisible ? false : true,
    config: config.default,
  });
  const animAws = useSpring({
    from: {
      opacity: 0,
      scale: 0,
    },
    to: {
      opacity: isVisible ? 1 : 0,
      scale: isVisible ? 1 : 0,
    },
    delay: 1600,
    reset: isVisible ? false : true,
    config: config.default,
  });
  const handleChange = (visible: boolean) => {
    if (visible) {
      setIsVisible(true);
    }
  };
  return (
    <IntersectionObserverWrapper
      id={`${ID_PREFIX}-intersection-observer`}
      pogrId={`${ID_PREFIX}-intersection-observer`}
      onChange={handleChange}
    >
      <Section
        id={`${ID_PREFIX}-main`}
        pogrId={`${ID_PREFIX}-main`}
        style={{ backgroundColor: "#132439" }}
      >
        <div
          id={`${ID_PREFIX}-container`}
          data-pogr-id={`${ID_PREFIX}-container`}
          className={styles.container}
        >
          <div
            id={`${ID_PREFIX}-icons-container`}
            data-pogr-id={`${ID_PREFIX}-icons-container`}
            className={styles.iconsContainer}
          >
            <SupportIcon icon="xbox-one" name="Xbox" style={animXbox} />
            <SupportIcon
              icon="playstation"
              name="Playstation"
              style={animPlaystation}
            />
            <SupportIcon icon="windows" name="Windows" style={animWindows} />
            <SupportIcon
              icon="nintendo-switch"
              name="Nintendo Switch"
              style={animNintendo}
            />
            <SupportIcon icon="ios" name="iOS" style={animIos} />
            <SupportIcon icon="mac" name="MacOS" style={animMacos} />
            <SupportIcon icon="android" name="Android" style={animAndroid} />
            <SupportIcon icon="linux" name="Linux Platform" style={animLinux} />
            <SupportIcon icon="steam" name="Steamdeck" style={animSteam} />
            <SupportIcon icon="kubernetes" name="Kubernetes" style={animKube} />
            <SupportIcon
              icon="gcp"
              name="Google Cloud Services"
              style={animGcp}
            />
            <SupportIcon
              icon="aws"
              name="Amazon Web Services"
              style={animAws}
            />
          </div>
          <animated.div
            id={`${ID_PREFIX}-title-container`}
            data-pogr-id={`${ID_PREFIX}-title-container`}
            style={animTitle}
          >
            <h1
              id={`${ID_PREFIX}-title`}
              data-pogr-id={`${ID_PREFIX}-title`}
              style={{ color: "#00DDDD" }}
            >
              Supporting all the major platforms...
            </h1>
          </animated.div>
        </div>
      </Section>
    </IntersectionObserverWrapper>
  );
}

export default PlatformSection;
