import { useParams } from "react-router-dom";
import Page from "../components/layouts/page/Page";
import AboutPogr from "../sections/about-pogr-section/AboutPogr";
import CareerApplySection from "../sections/career-apply-section/CareerApplySection";
import CareerDetailsHeroImageSection from "../sections/hero-image-section/career-details/CareerDetailsHeroImageSection";
import MainBlurbSection from "../sections/main-blurb-section/MainBlurbSection";

type Props = {};

const CareerDetailsPage = ({}: Props) => {
  const { id } = useParams();

  return (
    <Page>
      <CareerDetailsHeroImageSection openingId={id ?? ""} />
      <MainBlurbSection page="career-details" openingId={id ?? ""} />
      <CareerApplySection />
      <AboutPogr />
    </Page>
  );
};

export default CareerDetailsPage;
