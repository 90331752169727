import { useState } from "react";
import { useSpring, config, animated } from "react-spring";
import IntersectionObserverWrapper from "../../../components/intersection-observer-wrapper/IntersectionObserverWrapper";
import styles from "./PodcastInsights.module.scss";

type Insight = {
  url: string;
  width: number;
  height: number;
};

type Props = {
  insights: Insight[];
};

const ID_PREFIX = "podcast-insights";

const PodcastInsights = ({ insights }: Props) => {
  const [isVisible, setIsVisible] = useState(false);

  const handleChange = (visible: boolean) => {
    if (visible) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const anim1 = useSpring({
    from: {
      opacity: 0,
      y: -400,
    },
    to: {
      opacity: isVisible ? 1 : 0,
      y: isVisible ? 0 : -400,
    },
    delay: 300,
    reset: isVisible ? false : true,
    config: config.default,
  });
  
  const anim2 = useSpring({
    from: {
      opacity: 0,
      y: 400,
    },
    to: {
      opacity: isVisible ? 1 : 0,
      y: isVisible ? 0 : 400,
    },
    delay: 300,
    reset: isVisible ? false : true,
    config: config.default,
  });

  return (
    <IntersectionObserverWrapper
      id={`${ID_PREFIX}-dev-intersection-observer`}
      pogrId={`${ID_PREFIX}-dev-intersection-observer`}
      onChange={handleChange}
    >
      <div
        id={`${ID_PREFIX}-container`}
        data-pogr-id={`${ID_PREFIX}-container`}
        className={`${styles.container} `}
      >
        {insights.map((insight, index) => {
          return (
            <animated.figure
              key={`${ID_PREFIX}-image-container-${index}`}
              id={`${ID_PREFIX}-image-container-${index}`}
              data-pogr-id={`${ID_PREFIX}-image-container-${index}`}
              style={index % 2 === 0 ? anim1 : anim2}
            >
              <img
                id={`${ID_PREFIX}-image-${index}`}
                data-pogr-id={`${ID_PREFIX}-image-${index}`}
                src={insight.url}
                alt="A windmill"
                width="100%"
                height="100%"
              />
            </animated.figure>
          );
        })}
      </div>
    </IntersectionObserverWrapper>
  );
};

export default PodcastInsights;
