import Section from "../../components/layouts/section/Section";
import PodcastInsights from "../../modules/podcast/podcast-insights/PodcastInsights";
import insights from "../../demo-db/podcast/podcast-insight.json";
import { useSpring, config, animated } from "react-spring";
import styles from "./PodcastInsightsSection.module.scss";
import IntersectionObserverWrapper from "../../components/intersection-observer-wrapper/IntersectionObserverWrapper";
import { useState } from "react";

type Props = {};

const ID_PREFIX = "podcast-insights-section";

const PodcastInsightsSection = ({}: Props) => {
  const [isVisible, setIsVisible] = useState(false);
  const handleChange = (visible: boolean) => {
    if (visible) {
      setIsVisible(true);
    } else setIsVisible(false);
  };
  const anim = useSpring({
    from: {
      opacity: 0,
      y: 200,
    },
    to: {
      opacity: isVisible ? 1 : 0,
      y: isVisible ? 0 : 200,
    },
    delay: 200,
    reset: isVisible ? false : true,
    config: config.default,
  });
  return (
    <IntersectionObserverWrapper
      id={`${ID_PREFIX}-dev-intersection-observer`}
      pogrId={`${ID_PREFIX}-dev-intersection-observer`}
      onChange={handleChange}
    >
      <Section
        id={`${ID_PREFIX}-main`}
        pogrId={`${ID_PREFIX}-main`}
        style={{ backgroundColor: "#183B4B", overflow: "hidden" }}
      >
        <div
          id={`${ID_PREFIX}-container`}
          data-pogr-id={`${ID_PREFIX}-container`}
          className={`${styles.container}`}
        >
          <animated.div
            id={`${ID_PREFIX}-title`}
            data-pogr-id={`${ID_PREFIX}-title`}
            className={styles.title}
            style={anim}
          >
            LVLup insight
          </animated.div>
          <div
            id={`${ID_PREFIX}-images-container`}
            data-pogr-id={`${ID_PREFIX}-images-container`}
            className={styles.imagesContainer}
          >
            <PodcastInsights insights={insights.images} />
          </div>
        </div>
      </Section>
    </IntersectionObserverWrapper>
  );
};

export default PodcastInsightsSection;
