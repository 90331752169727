import React, { ReactNode } from 'react'
import Footer from '../../footer/Footer';
import Navbar from '../../navbar/Navbar'
import styles from './Page.module.scss';

type Props = {
  children?: ReactNode;
}

function Page({ children }: Props) {
  return (
    <div className={styles.page}>
      <Navbar />
      {children}
      <Footer />
    </div>
  )
}

export default Page