import React from 'react';
import Page from '../components/layouts/page/Page';
import SDKHeroImageSection from '../sections/hero-image-section/sdk/SDKHeroImageSection';
import MainBlurbSection from '../sections/main-blurb-section/MainBlurbSection';
import OurToolboxSection from '../sections/our-toolbox-section/OurToolboxSection';
import PlatformSection from '../sections/platforms-section/PlatformSection';
import SDKFeaturesSection from '../sections/sdk-features-section/SDKFeaturesSection';
import TakeoffSection from '../sections/takeoff-section/TakeoffSection';
import WaitlistSection from '../sections/waitlist-section/WaitlistSection';
import LanguageSection from '../sections/languages-section/LanguageSection';

function SDKPage() {
  return (
    <Page>
      <SDKHeroImageSection />
      <MainBlurbSection page='sdk' />
      {/* <SDKFeaturesSection /> */}
      <OurToolboxSection />
      <TakeoffSection />
      <PlatformSection />
      <LanguageSection />
      <WaitlistSection />
    </Page>
  );
}

export default SDKPage;
