import React, { useState } from 'react';
import styles from './TakeoffSection.module.scss';
import Section from '../../components/layouts/section/Section';
import IntersectionObserverWrapper from '../../components/intersection-observer-wrapper/IntersectionObserverWrapper';
import { animated, config, useSpring } from 'react-spring';

const ID_PREFIX = 'take-off-section';

function TakeoffSection() {
  const [isVisible, setIsVisible] = useState(false);
  const animRocket = useSpring({
    from: {
      opacity: 0,
      y: 400,
      x: -400,
    },
    to: {
      opacity: isVisible ? 1 : 0,
      y: isVisible ? 0 : 400,
      x: isVisible ? 0 : -400,
    },
    delay: 200,
    config: config.default,
  });
  const animTitle = useSpring({
    from: {
      opacity: 0,
      x: -400,
    },
    to: {
      opacity: isVisible ? 1 : 0,
      x: isVisible ? 0 : -400,
    },
    delay: 600,
    config: config.default,
  });
  const animDescription = useSpring({
    from: {
      opacity: 0,
      x: -400,
    },
    to: {
      opacity: isVisible ? 1 : 0,
      x: isVisible ? 0 : -400,
    },
    delay: 800,
    config: config.default,
  });
  const animIcon1 = useSpring({
    from: {
      opacity: 0,
      scale: 0,
    },
    to: {
      opacity: isVisible ? 1 : 0,
      scale: isVisible ? 1 : 0,
    },
    delay: 1100,
    config: config.default,
  });
  const animIcon2 = useSpring({
    from: {
      opacity: 0,
      scale: 0,
    },
    to: {
      opacity: isVisible ? 1 : 0,
      scale: isVisible ? 1 : 0,
    },
    delay: 1300,
    config: config.default,
  });
  const animIcon3 = useSpring({
    from: {
      opacity: 0,
      scale: 0,
    },
    to: {
      opacity: isVisible ? 1 : 0,
      scale: isVisible ? 1 : 0,
    },
    delay: 1500,
    config: config.default,
  });
  const animIcon4 = useSpring({
    from: {
      opacity: 0,
      scale: 0,
    },
    to: {
      opacity: isVisible ? 1 : 0,
      scale: isVisible ? 1 : 0,
    },
    delay: 1700,
    config: config.default,
  });
  const handleChange = (visible: boolean) => {
    if (visible) {
      setIsVisible(true);
    }
  };
  return (
    <IntersectionObserverWrapper
      id={`${ID_PREFIX}-intersection-observer`}
      pogrId={`${ID_PREFIX}-intersection-observer`}
      onChange={handleChange}
    >
      <Section
        id={`${ID_PREFIX}-main`}
        pogrId={`${ID_PREFIX}-main`}
        style={{
          backgroundColor: '#132439',
          backgroundImage: "url('/static/dots-background.png')",
          backgroundPosition: 'center center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'contain',
        }}
      >
        <div
          id={`${ID_PREFIX}-container`}
          data-pogr-id={`${ID_PREFIX}-container`}
          className={styles.container}
        >
          <div
            id={`${ID_PREFIX}-text-wrapper`}
            data-pogr-id={`${ID_PREFIX}-text-wrapper`}
            className={styles.textWrapper}
          >
            <animated.div
              id={`${ID_PREFIX}-coming-soon-container`}
              data-pogr-id={`${ID_PREFIX}-coming-soon-container`}
              className={styles.comingSoonText}
              style={animTitle}
            >
              COMING SOON
            </animated.div>
            <animated.div
              id={`${ID_PREFIX}-title-container`}
              data-pogr-id={`${ID_PREFIX}-title-container`}
              style={animTitle}
            >
              <h1
                id={`${ID_PREFIX}-title`}
                data-pogr-id={`${ID_PREFIX}-title`}
                style={{ color: '#00DDDD' }}
              >
                Level up with POGR
              </h1>
            </animated.div>
            <animated.div
              id={`${ID_PREFIX}-description-container`}
              data-pogr-id={`${ID_PREFIX}-description-container`}
              style={animDescription}
            >
              <p
                id={`${ID_PREFIX}-dscription`}
                data-pogr-id={`${ID_PREFIX}-dscription`}
                className={styles.description}
              >
                Create next-generation games using our developer design tool
                belt with POGR. Our SDK funnels data to our database where it is
                visualized in a easy to read way for our developers to make
                insightful decisions. <br /> POGR gives developers the tools
                they need to manage and grow their games for years to come. We
                bridge the gap between game developers and their communities,
                allowing for genuine innovation and aligning the game's goals.
              </p>
            </animated.div>
            <div
              id={`${ID_PREFIX}-partner-images-container`}
              data-pogr-id={`${ID_PREFIX}-partner-images-container`}
              className={styles.partnerRow}
            >
              <animated.img
                id={`${ID_PREFIX}-unity-image`}
                data-pogr-id={`${ID_PREFIX}-unity-image`}
                src='/static/socials/unity.png'
                alt='unity'
                style={animIcon1}
              />
              <animated.img
                id={`${ID_PREFIX}-unreal-engine-image`}
                data-pogr-id={`${ID_PREFIX}-unreal-engine-image`}
                src='/static/socials/unreal_engine.png'
                alt='ue'
                style={animIcon2}
              />
              <animated.img
                id={`${ID_PREFIX}-blazium-image`}
                data-pogr-id={`${ID_PREFIX}-blazium-image`}
                src='/static/socials/blazium.png'
                alt='godot'
                style={animIcon3}
              />
              <animated.img
                id={`${ID_PREFIX}-godot-image`}
                data-pogr-id={`${ID_PREFIX}-godot-image`}
                src='/static/socials/godot.png'
                alt='godot'
                style={animIcon4}
              />
            </div>
          </div>
          <animated.div
            id={`${ID_PREFIX}-background-image-container`}
            data-pogr-id={`${ID_PREFIX}-background-image-container`}
            className={styles.imageWrapper}
            style={animRocket}
          >
            <img
              id={`${ID_PREFIX}-background-image`}
              data-pogr-id={`${ID_PREFIX}-background-image`}
              src='/static/rocket.png'
              alt='rocket'
            />
          </animated.div>
        </div>
      </Section>
    </IntersectionObserverWrapper>
  );
}

export default TakeoffSection;
