import React from "react";
import convertMilliseconds from "../../../helpers/convertTime";
import parseDate from "../../../helpers/parseDate";
import styles from "./PodcastLatestItem.module.scss";
import { useNavigate } from "react-router-dom";

type Props = {
  episode?: any;
};

const ID_PREFIX = "podcast-latest-episode";

function PodcastLatestItem({ episode }: Props) {
  const navigate = useNavigate();
  const { name = "", description, images, release_date, duration_ms, id } = episode;
  const { timeString } = convertMilliseconds(duration_ms);

  const handleClick = () => {
    navigate(`/podcast/${id}`);
  };

  return (
    <div
      id={`${ID_PREFIX}-container`}
      data-pogr-id={`${ID_PREFIX}-container`}
      className={styles.container}
      onClick={handleClick}
    >
      <div
        id={`${ID_PREFIX}-image-wrapper`}
        data-pogr-id={`${ID_PREFIX}-image-wrapper`}
        className={styles.imageWrapper}
      >
        <img
          id={`${ID_PREFIX}-image`}
          data-pogr-id={`${ID_PREFIX}-image`}
          src={images[0]?.url}
          alt="thumbnail-img"
        />
      </div>
      <div
        id={`${ID_PREFIX}-content`}
        data-pogr-id={`${ID_PREFIX}-content`}
        className={styles.content}
      >
        <div
          id={`${ID_PREFIX}-title`}
          data-pogr-id={`${ID_PREFIX}-title`}
          className={styles.title}
        >
          {name}
        </div>
        <div
          id={`${ID_PREFIX}-player-container`}
          data-pogr-id={`${ID_PREFIX}-player-container`}
          className={styles.player}
        >
          <div
            id={`${ID_PREFIX}-play-button-wrapper`}
            data-pogr-id={`${ID_PREFIX}-play-button-wrapper`}
            className={styles.playButtonWrapper}
          >
            <img
              id={`${ID_PREFIX}-play-button-image`}
              data-pogr-id={`${ID_PREFIX}-play-button-image`}
              src="/static/icons/play.svg"
              alt="play-button"
              width={48}
              height={48}
            />
          </div>
          <div
            id={`${ID_PREFIX}-info-wrapper`}
            data-pogr-id={`${ID_PREFIX}-info-wrapper`}
            className={styles.infoWrapper}
          >
            <div
              id={`${ID_PREFIX}-duration`}
              data-pogr-id={`${ID_PREFIX}-duration`}
              className={styles.duration}
            >
              {timeString}
            </div>
            <div
              id={`${ID_PREFIX}-release-date`}
              data-pogr-id={`${ID_PREFIX}-release-date`}
              className={styles.date}
            >
              {parseDate(release_date, "yyyy-MM-dd")}
            </div>
          </div>
        </div>
        <div
          id={`${ID_PREFIX}-description`}
          data-pogr-id={`${ID_PREFIX}-description`}
          className={styles.description}
        >
          {description}
        </div>
      </div>
    </div>
  );
}

export default PodcastLatestItem;
