import React from "react";
import styles from "./Footer.module.scss";
import socials from "./pogr-socials.json";
import links from "./pogr-links.json";

type Props = {};

const ID_PREFIX = "footer";

function Footer({}: Props) {
  return (
    <div
      id={`${ID_PREFIX}-container`}
      data-pogr-id={`${ID_PREFIX}-container`}
      className={styles.container}
    >
      <div
        id={`${ID_PREFIX}-brand-container`}
        data-pogr-id={`${ID_PREFIX}-brand-container`}
        className={styles.brandContainer}
      >
        <img
          id={`${ID_PREFIX}-brand-image`}
          data-pogr-id={`${ID_PREFIX}-brand-image`}
          src="/static/brand/brand-vertical.png"
          alt="brand"
        />
      </div>
      <div
        id={`${ID_PREFIX}-product-section`}
        data-pogr-id={`${ID_PREFIX}-product-section`}
        className={styles.productSection}
      >
        <ul
          id={`${ID_PREFIX}-product-ul`}
          data-pogr-id={`${ID_PREFIX}-product-ul`}
          aria-label="Product"
        >
          <li
            id={`${ID_PREFIX}-beta-access-li`}
            data-pogr-id={`${ID_PREFIX}-beta-access-li`}
          >
            <a
              id={`${ID_PREFIX}-beta-access-link`}
              data-pogr-id={`${ID_PREFIX}-beta-access-link`}
              href={links.beta_access}
              target="_blank"
              rel="noreferrer"
            >
              Beta Access
            </a>
          </li>
          <li
            id={`${ID_PREFIX}-blog-news-li`}
            data-pogr-id={`${ID_PREFIX}-blog-news-li`}
          >
            <a
              id={`${ID_PREFIX}-blog-news-link`}
              data-pogr-id={`${ID_PREFIX}-blog-news-link`}
              href={links.blog_news}
              target="_blank"
              rel="noreferrer"
            >
              Blog & news
            </a>
          </li>
        </ul>
      </div>
      <div
        id={`${ID_PREFIX}-company-section`}
        data-pogr-id={`${ID_PREFIX}-company-section`}
        className={styles.companySection}
      >
        <ul
          id={`${ID_PREFIX}-company-ul`}
          data-pogr-id={`${ID_PREFIX}-company-ul`}
          aria-label="Company"
        >
          <li id={`${ID_PREFIX}-tos-li`} data-pogr-id={`${ID_PREFIX}-tos-li`}>
            <a
              id={`${ID_PREFIX}-tos-link`}
              data-pogr-id={`${ID_PREFIX}-tos-link`}
              href="/legal/tos"
            >
              Terms of Service
            </a>
          </li>
          <li
            id={`${ID_PREFIX}-privacy-policy-li`}
            data-pogr-id={`${ID_PREFIX}-privacy-policy-li`}
          >
            <a
              id={`${ID_PREFIX}-privacy-policy-link`}
              data-pogr-id={`${ID_PREFIX}-privacy-policy-link`}
              href="/legal/privacy"
            >
              Privacy Policy
            </a>
          </li>
        </ul>
      </div>
      <div
        id={`${ID_PREFIX}-social-section`}
        data-pogr-id={`${ID_PREFIX}-social-section`}
        className={styles.socialSection}
      >
        <ul
          id={`${ID_PREFIX}-get-in-touch-ul`}
          data-pogr-id={`${ID_PREFIX}-get-in-touch-ul`}
          aria-label="Get in touch with us"
        >
          <li
            id={`${ID_PREFIX}-social-li`}
            data-pogr-id={`${ID_PREFIX}-social-li`}
          >
            <div
              id={`${ID_PREFIX}-social-list-container`}
              data-pogr-id={`${ID_PREFIX}-social-list-container`}
              className={styles.socialList}
            >
              {socials.map((s, i) => {
                return (
                  <a
                    key={`social-link-${s.title}-${i}`}
                    id={`${ID_PREFIX}-link-${s.title}-${i}`}
                    data-pogr-id={`${ID_PREFIX}-link-${s.title}-${i}`}
                    href={s.url}
                    target="_blank"
                    className={styles.socialItem}
                    rel="noreferrer"
                  >
                    <img src={s.img} alt={s.title} />
                  </a>
                );
              })}
            </div>
          </li>
          <li id={`${ID_PREFIX}-mail-li`} data-pogr-id={`${ID_PREFIX}-mail-li`}>
            <a
              id={`${ID_PREFIX}-mail-link`}
              data-pogr-id={`${ID_PREFIX}-mail-link`}
              href="mailto:info@pogr.io"
            >
              info@pogr.io
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Footer;
