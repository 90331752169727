import React, { useEffect } from 'react'
import { scroller } from 'react-scroll'
import ReactGA from 'react-ga';
import { useSearchParams } from 'react-router-dom';
import Page from '../components/layouts/page/Page'
import FeaturesSection from '../sections/features-section/FeaturesSection'
import GamerHeroImageSection from '../sections/hero-image-section/gamer/GamerHeroImageSection'
import MainBlurbSection from '../sections/main-blurb-section/MainBlurbSection'
import TeamSection from '../sections/team-section/TeamSection'
import WaitlistSection from '../sections/waitlist-section/WaitlistSection'
import WidgetSection from '../sections/widget-section/WidgetSection'

type Props = {}

function HomePage({}: Props) {
  let [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
    if (searchParams.get('join') !== null) {
      scroller.scrollTo('waitlist', {
        offset: 100
      });
    }
  }, []);

  return (
    <Page>
      <GamerHeroImageSection />
      <MainBlurbSection />
      <FeaturesSection />
      <div style={{background: 'linear-gradient(180deg, rgba(107,38,54,0) 0%, rgba(107,38,54,1) 100%)'}}>
        <WidgetSection />
      </div>
      <TeamSection />
      <WaitlistSection isDev={false} />
    </Page>
  )
}

export default HomePage