import React from "react";
import styles from "./PricingHeroImage.module.scss";

type Props = {
  vidSrc?: string;
  imgSrc?: string;
};

const ID_PREFIX = "pricing-hero-image";

function PricingHeroImage({ vidSrc, imgSrc }: Props) {
  return (
    <div
      id={`${ID_PREFIX}-container`}
      data-pogr-id={`${ID_PREFIX}-container`}
      className={styles.container}
    >
      <video
        id={`${ID_PREFIX}-video`}
        data-pogr-id={`${ID_PREFIX}-video`}
        className="videoTag"
        preload="auto"
        autoPlay
        controls={false}
        loop
        muted
        playsInline
        webkit-playsinline="true"
        poster="/static/hero-images/submarine.webp"
      >
        <source
          id={`${ID_PREFIX}-video-source`}
          data-pogr-id={`${ID_PREFIX}-video-source`}
          src={vidSrc || "/static/hero-images/submarine.mp4"}
          type="video/mp4"
        />
        <img
          id={`${ID_PREFIX}-image`}
          data-pogr-id={`${ID_PREFIX}-image`}
          src={imgSrc || "/static/hero-images/submarine.webp"}
          alt="hero"
        />
      </video>
      <div
        id={`${ID_PREFIX}-top-gradient`}
        data-pogr-id={`${ID_PREFIX}-top-gradient`}
        className={styles.topGradient}
      />
      <div
        id={`${ID_PREFIX}-bottom-gradient`}
        data-pogr-id={`${ID_PREFIX}-bottom-gradient`}
        className={styles.bottomGradient}
      />
    </div>
  );
}

export default PricingHeroImage;
