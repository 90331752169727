import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Divider } from '@mui/material';
import { useEffect, useState } from 'react';
import IntersectionObserverWrapper from '../../components/intersection-observer-wrapper/IntersectionObserverWrapper';
import Section from '../../components/layouts/section/Section';
import styles from './MainBlurbSection.module.scss';
import careers from '../careers-openings-list-section/careers-dummy-data.json';
import { CareerType } from '../../types/career';

type Props = {
  page?: 'dev' | 'gamer' | 'sdk' | 'careers' | 'career-details';
  openingId?: string;
};

const ID_PREFIX = 'main-blurb-section';

function MainBlurbSection({ page, openingId }: Props) {
  const [isVisible, setIsVisible] = useState(false);
  const [currentOpening, setCurrentOpening] = useState<CareerType | null>(null);

  const handleChange = (visible: boolean) => {
    if (visible) setIsVisible(true);
  };

  useEffect(() => {
    if (openingId) {
      let openingWithId = (careers as CareerType[]).filter(
        (opening) => opening.id === openingId
      );
      setCurrentOpening(openingWithId[0]);
    }
  }, []);

  switch (page) {
    case 'dev':
      return (
        <Section
          id={`${ID_PREFIX}-dev-container`}
          pogrId={`${ID_PREFIX}-dev-container`}
        >
          <IntersectionObserverWrapper
            id={`${ID_PREFIX}-dev-intersection-observer`}
            pogrId={`${ID_PREFIX}-dev-intersection-observer`}
            onChange={handleChange}
          >
            <div
              id={`${ID_PREFIX}-dev-row`}
              data-pogr-id={`${ID_PREFIX}-dev-row`}
              className={`${styles.row} ${isVisible && styles.visible}`}
            >
              <img
                id={`${ID_PREFIX}-left-quote-image`}
                data-pogr-id={`${ID_PREFIX}-left-quote-image`}
                className={styles.leftQ}
                src={'/static/left-quote.svg'}
                alt=''
              />
              <div
                id={`${ID_PREFIX}-dev-content`}
                data-pogr-id={`${ID_PREFIX}-dev-content`}
                className={styles.content}
              >
                <h3>Providing tools for game developers to</h3>
                <br />
                <h1 style={{ color: '#FF9C41' }}>Visualize their data</h1>
              </div>
              <img
                id={`${ID_PREFIX}-right-quote-image`}
                data-pogr-id={`${ID_PREFIX}-right-quote-image`}
                className={styles.rightQ}
                src={'/static/right-quote.svg'}
                alt=''
              />
            </div>
          </IntersectionObserverWrapper>
        </Section>
      );
    case 'gamer':
      return (
        <Section
          id={`${ID_PREFIX}-gamer-container`}
          pogrId={`${ID_PREFIX}-gamer-container`}
        >
          <IntersectionObserverWrapper
            id={`${ID_PREFIX}-gamer-intersection-observer`}
            pogrId={`${ID_PREFIX}-gamer-intersection-observer`}
            onChange={handleChange}
          >
            <div
              id={`${ID_PREFIX}-gamer-row`}
              data-pogr-id={`${ID_PREFIX}-gamer-row`}
              className={`${styles.row} ${isVisible && styles.visible}`}
            >
              <img
                id={`${ID_PREFIX}-gamer-left-quote-image`}
                data-pogr-id={`${ID_PREFIX}-gamer-left-quote-image`}
                className={styles.leftQ}
                src={'/static/left-quote.svg'}
                alt=''
              />
              <div
                id={`${ID_PREFIX}-gamer-content`}
                data-pogr-id={`${ID_PREFIX}-gamer-content`}
                className={styles.content}
              >
                <h3>Building the statistical foundation for</h3>
                <br />
                <h1>The future of gaming</h1>
              </div>
              <img
                id={`${ID_PREFIX}-gamer-right-quote-image`}
                data-pogr-id={`${ID_PREFIX}-gamer-right-quote-image`}
                className={styles.rightQ}
                src={'/static/right-quote.svg'}
                alt=''
              />
            </div>
          </IntersectionObserverWrapper>
        </Section>
      );
    case 'sdk':
      return (
        <Section
          id={`${ID_PREFIX}-sdk-container`}
          pogrId={`${ID_PREFIX}-sdk-container`}
          style={{
            backgroundColor: '#132439',
            paddingTop: '6rem',
            paddingBottom: '6rem',
            backgroundImage: "url('/static/clouds.svg')",
            backgroundPosition: 'center center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'contain',
          }}
        >
          <IntersectionObserverWrapper
            id={`${ID_PREFIX}-sdk-intersection-observer`}
            pogrId={`${ID_PREFIX}-sdk-intersection-observer`}
            onChange={handleChange}
          >
            <div
              id={`${ID_PREFIX}-sdk-row`}
              data-pogr-id={`${ID_PREFIX}-sdk-row`}
              className={`${styles.row} ${isVisible && styles.visible}`}
            >
              <div
                id={`${ID_PREFIX}-sdk-content`}
                data-pogr-id={`${ID_PREFIX}-sdk-content`}
                className={styles.content}
              >
                <h1>Much more than just an SDK</h1>
                <div
                  id={`${ID_PREFIX}-sdk-content-description`}
                  data-pogr-id={`${ID_PREFIX}-sdk-content-description`}
                  className={styles.description}
                >
                  POGR allow game developers to access data that usually isn’t
                  collected. We enable developers to decide what data is being
                  collected and the ability to visualize it how they want. We
                  are replacing the services needed for game development: game
                  data/analytics, user management, user profiles, and more by
                  consolidating them into ONE solution. We make it easy by
                  providing a drag-and-drop-like solution for game engines that
                  centralizes all analytics. Look forward to additional features
                  in the future such as allowing you to manage tickets, CI/CD,
                  infrastructure, and much, much more.
                </div>
                <div
                  id={`${ID_PREFIX}-sdk-button-container`}
                  data-pogr-id={`${ID_PREFIX}-sdk-button-container`}
                  style={{ marginTop: '2rem' }}
                >
                  <Button
                    id={`${ID_PREFIX}-sdk-integrate-button`}
                    data-pogr-id={`${ID_PREFIX}-sdk-integrate-button`}
                    variant='contained'
                    size='large'
                    endIcon={<FontAwesomeIcon icon={faAngleRight} />}
                  >
                    Integrate our SDK
                  </Button>
                </div>
              </div>
            </div>
          </IntersectionObserverWrapper>
        </Section>
      );
    case 'careers':
      return (
        <Section
          id={`${ID_PREFIX}-careers-container`}
          pogrId={`${ID_PREFIX}-careers-container`}
          style={{ backgroundColor: '#292936' }}
        >
          <IntersectionObserverWrapper
            id={`${ID_PREFIX}-default-intersection-observer`}
            pogrId={`${ID_PREFIX}-default-intersection-observer`}
            onChange={handleChange}
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
              alignItems: 'center',
            }}
          >
            <div
              id={`${ID_PREFIX}-container`}
              data-pogr-id={`${ID_PREFIX}-container`}
              className={styles.container}
              style={{ gap: 0 }}
            >
              <div
                id={`${ID_PREFIX}-title-wrapper`}
                data-pogr-id={`${ID_PREFIX}-title-wrapper`}
                className={styles.titleWrapper}
              >
                <div
                  id={`${ID_PREFIX}-title-container`}
                  data-pogr-id={`${ID_PREFIX}-title-container`}
                >
                  <p
                    id={`${ID_PREFIX}-title`}
                    data-pogr-id={`${ID_PREFIX}-title`}
                    className={styles.title}
                  >
                    Next-generation platform
                  </p>
                </div>
                <div
                  id={`${ID_PREFIX}-description-container`}
                  data-pogr-id={`${ID_PREFIX}-description-container`}
                >
                  <p
                    id={`${ID_PREFIX}-description`}
                    data-pogr-id={`${ID_PREFIX}-description`}
                    className={styles.description}
                  >
                    POGR (Play of the Game Rating) is a next-generation platform
                    fueled by data and analytics that enables the ultimate
                    player profiles. The system allows you to track all your
                    gaming statistics: yes, all of them, including your social
                    media. The profiles aggregate all the analytics from your
                    gaming and social media accounts onto one customizable
                    platform, enabling you to manage multiple accounts at once
                    with tons of analytical feedback to boot.
                  </p>
                </div>
              </div>
              <Divider className={styles.divider} />
            </div>
          </IntersectionObserverWrapper>
        </Section>
      );
    case 'career-details':
      return (
        <Section
          id={`${ID_PREFIX}-careers-container`}
          pogrId={`${ID_PREFIX}-careers-container`}
          style={{ backgroundColor: '#292936', paddingTop: '5rem' }}
        >
          <IntersectionObserverWrapper
            id={`${ID_PREFIX}-default-intersection-observer`}
            pogrId={`${ID_PREFIX}-default-intersection-observer`}
            onChange={handleChange}
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
              alignItems: 'center',
            }}
          >
            <div
              id={`${ID_PREFIX}-container`}
              data-pogr-id={`${ID_PREFIX}-container`}
              className={styles.container}
              style={{ gap: '56px', alignItems: 'baseline' }}
            >
              <div
                id={`${ID_PREFIX}-title-wrapper`}
                data-pogr-id={`${ID_PREFIX}-title-wrapper`}
                className={styles.titleWrapper}
              >
                <div
                  id={`${ID_PREFIX}-title-container`}
                  data-pogr-id={`${ID_PREFIX}-title-container`}
                >
                  <p
                    id={`${ID_PREFIX}-title`}
                    data-pogr-id={`${ID_PREFIX}-title`}
                    className={styles.title}
                  >
                    Overview
                  </p>
                </div>
                <div
                  id={`${ID_PREFIX}-description-container`}
                  data-pogr-id={`${ID_PREFIX}-description-container`}
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '8px',
                  }}
                >
                  <p
                    id={`${ID_PREFIX}-description`}
                    data-pogr-id={`${ID_PREFIX}-description`}
                    className={styles.description}
                    style={{ textAlign: 'left' }}
                  >
                    {currentOpening?.overview}
                  </p>
                </div>
              </div>
              <div
                id={`${ID_PREFIX}-title-wrapper`}
                data-pogr-id={`${ID_PREFIX}-title-wrapper`}
                className={styles.titleWrapper}
              >
                <div
                  id={`${ID_PREFIX}-title-container`}
                  data-pogr-id={`${ID_PREFIX}-title-container`}
                >
                  <p
                    id={`${ID_PREFIX}-title`}
                    data-pogr-id={`${ID_PREFIX}-title`}
                    className={styles.title}
                  >
                    What you'll do
                  </p>
                </div>
                <div
                  id={`${ID_PREFIX}-description-container`}
                  data-pogr-id={`${ID_PREFIX}-description-container`}
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '8px',
                  }}
                >
                  <ul
                    id={`${ID_PREFIX}-description`}
                    data-pogr-id={`${ID_PREFIX}-description`}
                    className={styles.description}
                    style={{
                      paddingLeft: '24px',
                      margin: 0,
                      textAlign: 'left',
                    }}
                  >
                    {currentOpening?.responsibilities.map((res, index) => {
                      return <li key={index}>{res}</li>;
                    })}
                  </ul>
                </div>
              </div>
              <div
                id={`${ID_PREFIX}-title-wrapper`}
                data-pogr-id={`${ID_PREFIX}-title-wrapper`}
                className={styles.titleWrapper}
              >
                <div
                  id={`${ID_PREFIX}-title-container`}
                  data-pogr-id={`${ID_PREFIX}-title-container`}
                >
                  <p
                    id={`${ID_PREFIX}-title`}
                    data-pogr-id={`${ID_PREFIX}-title`}
                    className={styles.title}
                  >
                    Who you are
                  </p>
                </div>
                <div
                  id={`${ID_PREFIX}-description-container`}
                  data-pogr-id={`${ID_PREFIX}-description-container`}
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '8px',
                  }}
                >
                  <ul
                    id={`${ID_PREFIX}-description`}
                    data-pogr-id={`${ID_PREFIX}-description`}
                    className={styles.description}
                    style={{
                      paddingLeft: '24px',
                      margin: 0,
                      textAlign: 'left',
                    }}
                  >
                    {currentOpening?.requirements.map((res, index) => {
                      return <li key={index}>{res}</li>;
                    })}
                  </ul>
                </div>
                <Divider className={styles.divider} />
              </div>
            </div>
          </IntersectionObserverWrapper>
        </Section>
      );
    default:
      return (
        <Section
          id={`${ID_PREFIX}-default-container`}
          pogrId={`${ID_PREFIX}-default-container`}
        >
          <IntersectionObserverWrapper
            id={`${ID_PREFIX}-default-intersection-observer`}
            pogrId={`${ID_PREFIX}-default-intersection-observer`}
            onChange={handleChange}
          >
            <div
              id={`${ID_PREFIX}-default-row`}
              data-pogr-id={`${ID_PREFIX}-default-row`}
              className={`${styles.row} ${isVisible && styles.visible}`}
            >
              <img
                id={`${ID_PREFIX}-default-left-quote-image`}
                data-pogr-id={`${ID_PREFIX}-default-left-quote-image`}
                className={styles.leftQ}
                src={'/static/left-quote.svg'}
                alt=''
              />
              <div
                id={`${ID_PREFIX}-default-content`}
                data-pogr-id={`${ID_PREFIX}-default-content`}
                className={styles.content}
              >
                <h3>Building the statistical foundation for</h3>
                <br />
                <h1>The future of gaming</h1>
              </div>
              <img
                id={`${ID_PREFIX}-default-right-quote-image`}
                data-pogr-id={`${ID_PREFIX}-default-right-quote-image`}
                className={styles.rightQ}
                src={'/static/right-quote.svg'}
                alt=''
              />
            </div>
          </IntersectionObserverWrapper>
        </Section>
      );
  }
}

export default MainBlurbSection;
