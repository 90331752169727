import styles from "./SupportIcon.module.scss";
import { useSpring, config, animated } from "react-spring";

type Props = {
  icon: string;
  name: string;
  style?: any;
};

const ID_PREFIX = "support-icon";

const SupportIcon = ({ icon, name, style }: Props) => {
  return (
    <animated.div
      id={`${ID_PREFIX}-container`}
      data-pogr-id={`${ID_PREFIX}-container`}
      className={styles.container}
      style={style}
    >
      <img
        id={`${ID_PREFIX}-image`}
        data-pogr-id={`${ID_PREFIX}-image`}
        src={"/static/support-icons/blue-tick-mark.svg"}
        alt="Blue tick Icon"
        className={styles.blueTick}
      />
      <img
        id={`${ID_PREFIX}-image`}
        data-pogr-id={`${ID_PREFIX}-image`}
        src={`/static/support-icons/${icon}.svg`}
        alt="Support Icon"
      />
      <div
        id={`${ID_PREFIX}-logo-name`}
        data-pogr-id={`${ID_PREFIX}-logo-name`}
        className={styles.logoName}
      >
        {name}
      </div>
    </animated.div>
  );
};

export default SupportIcon;
