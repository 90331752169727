import React from "react";
import { useNavigate } from "react-router-dom";
import convertMilliseconds from "../../../helpers/convertTime";
import parseDate from "../../../helpers/parseDate";
import styles from "./PodcastEpisodesList.module.scss";

type Props = {
  episodes?: Array<any>;
};

const ID_PREFIX = "podcast-episodes-list";

const PodcastEpisodeListItem = ({
  episode,
  uniqueId,
  pogrId,
}: {
  episode: any;
  uniqueId?: string;
  pogrId?: string;
}) => {
  const navigate = useNavigate();
  const {
    name = "",
    description,
    images,
    release_date,
    duration_ms,
    id,
  } = episode;
  const { timeString } = convertMilliseconds(duration_ms);

  const handleClick = () => {
    navigate(`/podcast/${id}`);
  };

  return (
    <tr
      id={`${uniqueId}-container`}
      data-pogr-id={`${pogrId || uniqueId}-container`}
      onClick={handleClick}
    >
      <td
        id={`${uniqueId}-thumbnail-container`}
        data-pogr-id={`${pogrId || uniqueId}-thumbnail-container`}
        className={styles.thumbnail}
      >
        <div
          id={`${uniqueId}-thumbnail-wrapper`}
          data-pogr-id={`${pogrId || uniqueId}-thumbnail-wrapper`}
          className={styles.thumbnailImgWrapper}
        >
          <img
            id={`${uniqueId}-thumbnail`}
            data-pogr-id={`${pogrId || uniqueId}-thumbnail`}
            src={images[0]?.url}
            alt="thumbnail-img"
          />
        </div>
      </td>
      <td
        id={`${uniqueId}-episode-container`}
        data-pogr-id={`${pogrId || uniqueId}-episode-container`}
        className={styles.episodeTd}
      >
        <div
          id={`${uniqueId}-episode-info-wrapper`}
          data-pogr-id={`${pogrId || uniqueId}-episode-info-wrapper`}
          className={styles.episodeInfoWrapper}
        >
          <div
            id={`${uniqueId}-episode-title`}
            data-pogr-id={`${pogrId || uniqueId}-episode-title`}
            className={styles.name}
          >
            {name}
          </div>
          <div
            id={`${uniqueId}-episode-description`}
            data-pogr-id={`${pogrId || uniqueId}-episode-description`}
            className={styles.description}
          >
            {description}
          </div>
        </div>
      </td>
      <td
        id={`${uniqueId}-publised-date-container`}
        data-pogr-id={`${pogrId || uniqueId}-publised-date-container`}
        className={styles.publishedTd}
      >
        <div
          id={`${uniqueId}-oublished-date`}
          data-pogr-id={`${pogrId || uniqueId}-oublished-date`}
        >
          {parseDate(release_date, "yyyy-MM-dd", "MMM dd, y")}
        </div>
      </td>
      <td
        id={`${uniqueId}-duration-container`}
        data-pogr-id={`${pogrId || uniqueId}-duration-container`}
        className={styles.lengthTd}
      >
        <div
          id={`${uniqueId}-duration`}
          data-pogr-id={`${pogrId || uniqueId}-duration`}
        >
          {timeString}
        </div>
      </td>
      <td
        id={`${uniqueId}-play-button-container`}
        data-pogr-id={`${pogrId || uniqueId}-play-button-container`}
        className={styles.playButtonTd}
      >
        <div
          id={`${uniqueId}-play-button-wrapper`}
          data-pogr-id={`${pogrId || uniqueId}-play-button-wrapper`}
          className={styles.playButtonWrapper}
        >
          <img
            id={`${uniqueId}-play-button-image`}
            data-pogr-id={`${pogrId || uniqueId}-play-button-image`}
            src="/static/icons/play.svg"
            alt="play-button"
            width={32}
            height={32}
          />
        </div>
      </td>
    </tr>
  );
};

function PodcastEpisodesList({ episodes = [] }: Props) {
  return (
    <div
      id={`${ID_PREFIX}-container`}
      data-pogr-id={`${ID_PREFIX}-container`}
      className={styles.container}
    >
      <table
        id={`${ID_PREFIX}-table`}
        data-pogr-id={`${ID_PREFIX}-table`}
        cellSpacing="0"
        cellPadding="0"
      >
        <thead
          id={`${ID_PREFIX}-table-header`}
          data-pogr-id={`${ID_PREFIX}-table-header`}
        >
          <tr
            id={`${ID_PREFIX}-table-header-row`}
            data-pogr-id={`${ID_PREFIX}-table-header-row`}
          >
            <th></th>
            <th>EPISODE</th>
            <th>PUBLISHED</th>
            <th>LENGTH</th>
            <th></th>
          </tr>
        </thead>
        <tbody
          id={`${ID_PREFIX}-table-body`}
          data-pogr-id={`${ID_PREFIX}-table-body`}
        >
          {episodes.map((e, i) => (
            <PodcastEpisodeListItem
              uniqueId={`${ID_PREFIX}-item-${i}`}
              pogrId={`${ID_PREFIX}-item-${i}`}
              key={`podcast-episode-list-item-${i}`}
              episode={e}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default PodcastEpisodesList;
