import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretRight } from '@fortawesome/free-solid-svg-icons';
import { Button } from '@mui/material';
import React from 'react';
import { scroller } from 'react-scroll';
import { useSpring, config, animated } from 'react-spring';
import HeroImage from '../../../components/layouts/hero-image/HeroImage';
import Section from '../../../components/layouts/section/Section';
import styles from '../HeroImageSection.module.scss';

const ID_PREFIX = 'gamer-hero-image-section';

function GamerHeroImageSection() {
  const anim = useSpring({
    from: {
      opacity: 0,
      x: -200,
    },
    to: {
      opacity: 1,
      x: 0,
    },
    config: config.default,
  });

  const anim2 = useSpring({
    from: {
      opacity: 0,
      x: -200,
    },
    to: {
      opacity: 1,
      x: 0,
    },
    config: config.default,
    delay: 300,
  });

  const anim3 = useSpring({
    from: {
      opacity: 0,
      x: -200,
    },
    to: {
      opacity: 1,
      x: 0,
    },
    config: config.default,
    delay: 600,
  });

  const anim4 = useSpring({
    from: {
      x: 100,
      opacity: 0,
    },
    to: {
      x: 0,
      opacity: 1,
    },
    config: config.default,
    delay: 1200,
  });

  const handleButtonClick = () => {
    scroller.scrollTo('waitlist', {
      duration: 1000,
      delay: 0,
      smooth: true,
      offset: 100,
    });
  };

  return (
    <Section
      id={`${ID_PREFIX}-container`}
      data-pogr-id={`${ID_PREFIX}-container`}
    >
      <HeroImage />
      <div
        id={`${ID_PREFIX}-text-overlay`}
        data-pogr-id={`${ID_PREFIX}-text-overlay`}
        className={styles.textOverlay}
      >
        <div
          id={`${ID_PREFIX}-text-box`}
          data-pogr-id={`${ID_PREFIX}-text-box`}
          className={styles.textBox}
        >
          <animated.div
            id={`${ID_PREFIX}-made-for-gamers-animated-div`}
            data-pogr-id={`${ID_PREFIX}-made-for-gamers-animated-div`}
            style={anim}
            className={styles.bubble}
          >
            <h4
              id={`${ID_PREFIX}-made-for-gamers-text`}
              data-pogr-id={`${ID_PREFIX}-made-for-gamers-text`}
            >
              Made for Gamers
            </h4>
          </animated.div>
          <animated.h1
            id={`${ID_PREFIX}-level-up-animated-text`}
            data-pogr-id={`${ID_PREFIX}-level-up-animated-text`}
            style={anim2}
          >
            LEVEL UP
            <br />
            YOUR GAME
          </animated.h1>
          <animated.h4
            id={`${ID_PREFIX}-engage-animated-text`}
            data-pogr-id={`${ID_PREFIX}-engage-animated-text`}
            style={anim3}
          >
            The first profile that focuses on a gamers online journey. We
            increase your discoverability, provide accurate leaderboards and
            give you a feature rich platform specific to the growing world of
            gaming.
          </animated.h4>
          <animated.div
            id={`${ID_PREFIX}-join-animated-button-container`}
            data-pogr-id={`${ID_PREFIX}-join-animated-button-container`}
            style={anim4}
          >
            <Button
              id={`${ID_PREFIX}-join-button`}
              data-pogr-id={`${ID_PREFIX}-join-button`}
              variant='contained'
              color='primary'
              size='large'
              endIcon={
                <FontAwesomeIcon icon={faCaretRight} height={8} width={6} />
              }
              onClick={handleButtonClick}
            >
              Join Now
            </Button>
          </animated.div>
        </div>
      </div>
    </Section>
  );
}

export default GamerHeroImageSection;
