import React, { useEffect, useState } from 'react';
import styles from './Navbar.module.scss';
import navbarItems from './navbar-items.json';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import useScrollPosition from '../../hooks/useScrollPosition';
import useWindowDimensions from '../../hooks/useWindowDimesions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { Button, Drawer } from '@mui/material';

type NavItemProps = {
  id?: string;
  pogrId?: string;
  item: {
    title: string;
    route?: string;
  };
  pathname?: string;
};

const ID_PREFIX = 'navbar';

const NavItem = ({ id, pogrId, item, pathname = '' }: NavItemProps) => {
  const navigate = useNavigate();
  const firstPath = pathname.split('/')[1];
  const match = item.route === `/${firstPath}`;

  const handleClick = () => {
    item.route && navigate(item.route);
  };

  return (
    <div
      id={`${id}-container`}
      data-pogr-id={`${pogrId || id}-container`}
      className={`${styles.itemContainer} ${match && styles.active}`}
      onClick={handleClick}
    >
      <h4 id={`${id}-title`} data-pogr-id={`${pogrId || id}-title`}>
        {item.title}
      </h4>
    </div>
  );
};

const NavItemMobile = ({ id, pogrId, item, pathname = '' }: NavItemProps) => {
  const navigate = useNavigate();
  const firstPath = pathname.split('/')[1];
  const match = item.route === `/${firstPath}`;

  const handleClick = () => {
    item.route && navigate(item.route);
  };
  return (
    <Button
      id={`${id}-button-container`}
      data-pogr-id={`${pogrId || id}-button-container`}
      size='large'
      onClick={handleClick}
    >
      <div
        id={`${id}-button`}
        data-pogr-id={`${pogrId || id}-button`}
        className={`${styles.navItemMobile} ${match && styles.active}`}
      >
        {item.title}
      </div>
    </Button>
  );
};

function Navbar() {
  const scrollPosition = useScrollPosition();
  const { isTablet } = useWindowDimensions();
  const location = useLocation();
  const [dark, setDark] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);

  useEffect(() => {
    if (scrollPosition > 120) {
      setDark(true);
    } else setDark(false);
  }, [scrollPosition]);

  if (isTablet) {
    return (
      <div
        id={`${ID_PREFIX}-table-container`}
        data-pogr-id={`${ID_PREFIX}-table-container`}
        className={`${styles.container} ${
          dark ? styles.darkmode : styles.lightmode
        }`}
      >
        <div
          id={`${ID_PREFIX}-table-content`}
          data-pogr-id={`${ID_PREFIX}-table-content`}
          className={styles.content}
        >
          <div
            id={`${ID_PREFIX}-brand-container`}
            data-pogr-id={`${ID_PREFIX}-brand-container`}
            className={styles.brandContainer}
          >
            <img
              id={`${ID_PREFIX}-brand-image`}
              data-pogr-id={`${ID_PREFIX}-brand-image`}
              src='/static/brand/brand-horizontal.png'
              alt='brand'
            />
          </div>
          <div
            id={`${ID_PREFIX}-menu-container`}
            data-pogr-id={`${ID_PREFIX}-menu-container`}
            className={styles.menuOpenContainer}
          >
            <FontAwesomeIcon
              id={`${ID_PREFIX}-open-menu-icon`}
              data-pogr-id={`${ID_PREFIX}-open-menu-icon`}
              icon={faBars}
              color='white'
              size='xl'
              onClick={() => setMenuOpen(!menuOpen)}
            />
          </div>
        </div>
        <Drawer
          id={`${ID_PREFIX}-menu-drawer`}
          data-pogr-id={`${ID_PREFIX}-menu-drawer`}
          PaperProps={{
            style: {
              height: '100%',
              width: '100%',
              maxWidth: '300px',
              backgroundColor: '#1A1A22',
            },
          }}
          anchor='left'
          open={menuOpen}
          onClose={() => setMenuOpen(false)}
        >
          <div
            id={`${ID_PREFIX}-tablet-nav-links`}
            data-pogr-id={`${ID_PREFIX}-tablet-nav-links`}
            className={styles.navlinksContainerMobile}
          >
            <div className={styles.navBrandingMobile}>
              <img
                src={'/static/brand/brand-logo.svg'}
                alt='brand'
                height={40}
              />
            </div>
            {navbarItems.map((item, i) => (
              <NavItemMobile
                key={`navbar-item-${item.title}`}
                id={`${ID_PREFIX}-nav-item-${item.title}`}
                pogrId={`${ID_PREFIX}-nav-item-${item.title}`}
                item={item}
                pathname={location.pathname}
              />
            ))}
          </div>
        </Drawer>
      </div>
    );
  }

  return (
    <div
      id={`${ID_PREFIX}-container`}
      data-pogr-id={`${ID_PREFIX}-container`}
      className={`${styles.container} ${
        dark ? styles.darkmode : styles.lightmode
      }`}
    >
      <div
        id={`${ID_PREFIX}-content`}
        data-pogr-id={`${ID_PREFIX}-content`}
        className={styles.content}
      >
        <Link to={'/'}>
          <div
            id={`${ID_PREFIX}-brand-container`}
            data-pogr-id={`${ID_PREFIX}-brand-container`}
            className={styles.brandContainer}
          >
            <img
              id={`${ID_PREFIX}-brand-image`}
              data-pogr-id={`${ID_PREFIX}-brand-image`}
              src='/static/brand/brand-horizontal.png'
              alt='brand'
            />
          </div>
        </Link>
        <div
          id={`${ID_PREFIX}-nav-links-container`}
          data-pogr-id={`${ID_PREFIX}-nav-links-container`}
          className={styles.navlinksContainer}
        >
          {navbarItems.map((item, i) => (
            <NavItem
              key={`navbar-item-${item.title}`}
              id={`${ID_PREFIX}-nav-item-${item.title}`}
              pogrId={`${ID_PREFIX}-nav-item-${item.title}`}
              item={item}
              pathname={location.pathname}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

export default Navbar;
