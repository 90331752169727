import React, { useState } from "react";
import Section from "../../components/layouts/section/Section";
import styles from "./FeaturesSection.module.scss";
import devFeatures from "./dev-features.json";
import gamerFeatures from "./gamer-features.json";
import IntersectionObserverWrapper from "../../components/intersection-observer-wrapper/IntersectionObserverWrapper";

type Props = {
  page?: "dev" | "gamer";
};

type FeatureItemProps = {
  id?: string;
  pogrId?: string;
  index: number;
  img: string;
  title: string;
  desc: string;
};

const ID_PREFIX = "features-section";

const FeatureItem = ({
  id,
  pogrId,
  index,
  img,
  title,
  desc,
}: FeatureItemProps) => {
  const [isVisible, setIsVisible] = useState(false);

  const handleChange = (visible: boolean) => {
    if (visible) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  return (
    <IntersectionObserverWrapper
      id={`${id}-intersection-observer`}
      pogrId={`${pogrId || id}-intersection-observer`}
      onChange={handleChange}
    >
      <div
        id={`${id}-container`}
        data-pogr-id={`${pogrId || id}-container`}
        className={`${styles.item} ${isVisible && styles.visible}`}
      >
        <img
          id={`${id}-image`}
          data-pogr-id={`${pogrId || id}-image`}
          src={img}
          alt=""
        />
        <div
          id={`${id}-text-block`}
          data-pogr-id={`${pogrId || id}-text-block`}
          className={styles.textBlock}
        >
          <div
            id={`${id}-image-padding`}
            data-pogr-id={`${pogrId || id}-image-padding`}
            className={styles.imgPadding}
          ></div>
          <h3
            id={`${id}-title`}
            data-pogr-id={`${pogrId || id}-title`}
            className={styles.title}
          >
            {title}
          </h3>
          <div
            id={`${id}-description`}
            data-pogr-id={`${pogrId || id}-description`}
            className={styles.desc}
          >
            {desc}
          </div>
        </div>
      </div>
    </IntersectionObserverWrapper>
  );
};

function FeaturesSection({ page }: Props) {
  let features;
  switch (page) {
    case "dev":
      features = devFeatures;
      break;
    case "gamer":
      features = gamerFeatures;
      break;
    default:
      features = gamerFeatures;
      break;
  }

  return (
    <Section
      id={`${ID_PREFIX}-main-section`}
      pogrId={`${ID_PREFIX}-main-section`}
    >
      <div
        id={`${ID_PREFIX}-container`}
        data-pogr-id={`${ID_PREFIX}-container`}
        className={styles.container}
      >
        {features.map((f, i) => {
          return (
            <FeatureItem
              key={`features-item-${f.title}-${i}`}
              id={`${ID_PREFIX}-feature-item-${f.title}-${i}`}
              pogrId={`${ID_PREFIX}-feature-item-${f.title}-${i}`}
              {...f}
              index={i}
            />
          );
        })}
      </div>
    </Section>
  );
}

export default FeaturesSection;
