import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCreditCard } from "@fortawesome/free-regular-svg-icons";
import Section from "../../components/layouts/section/Section";
import styles from "./PricingTableSection.module.scss";
import { faCoins, faTrophy } from "@fortawesome/free-solid-svg-icons";
import pricingData from "../../demo-db/pricing/pricing-data.json";
import ComingSoonButton from "../../modules/coming-soon-button/ComingSoonButton";

type Props = {};

const ID_PREFIX = "pricing-table-section";

function PricingTableSection({}: Props) {
  return (
    <Section
      id={`${ID_PREFIX}-main`}
      pogrId={`${ID_PREFIX}-main`}
      style={{
        backgroundColor: "#132439",
        backgroundImage: "url('/static/clouds.svg')",
        backgroundPosition: "center center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "contain",
      }}
    >
      <div
        id={`${ID_PREFIX}-container`}
        data-pogr-id={`${ID_PREFIX}-container`}
        className={styles.container}
      >
        <div
          id={`${ID_PREFIX}-title-wrapper`}
          data-pogr-id={`${ID_PREFIX}-title-wrapper`}
          className={styles.titleWrapper}
        >
          <div
            id={`${ID_PREFIX}-title-container`}
            data-pogr-id={`${ID_PREFIX}-title-container`}
          >
            <h2 id={`${ID_PREFIX}-title`} data-pogr-id={`${ID_PREFIX}-title`}>
              Pricing
            </h2>
          </div>
          <div
            id={`${ID_PREFIX}-description-container`}
            data-pogr-id={`${ID_PREFIX}-description-container`}
          >
            <p
              id={`${ID_PREFIX}-description`}
              data-pogr-id={`${ID_PREFIX}-description`}
            >
              Please reach out and set up a call with our team. Our initial offering may be free for your studio!
            </p>
          </div>
        </div>
        <div
          id={`${ID_PREFIX}-table-container`}
          data-pogr-id={`${ID_PREFIX}-table-container`}
          className={styles.tableWrapper}
        >
          {/* <table id={`${ID_PREFIX}-table`} data-pogr-id={`${ID_PREFIX}-table`}>
            <colgroup
              id={`${ID_PREFIX}-table-colgroup`}
              data-pogr-id={`${ID_PREFIX}-table-colgroup`}
            >
              <col
                id={`${ID_PREFIX}-table-colgroup-col-0`}
                data-pogr-id={`${ID_PREFIX}-table-colgroup-col-0`}
              />
              <col
                id={`${ID_PREFIX}-table-colgroup-col-1`}
                data-pogr-id={`${ID_PREFIX}-table-colgroup-col-1`}
              />
              <col
                id={`${ID_PREFIX}-table-colgroup-col-2`}
                data-pogr-id={`${ID_PREFIX}-table-colgroup-col-2`}
              />
              <col
                id={`${ID_PREFIX}-table-colgroup-col-3`}
                data-pogr-id={`${ID_PREFIX}-table-colgroup-col-3`}
              />
            </colgroup>
            <thead
              id={`${ID_PREFIX}-table-head`}
              data-pogr-id={`${ID_PREFIX}-table-head`}
            >
              <tr
                id={`${ID_PREFIX}-table-head-row`}
                data-pogr-id={`${ID_PREFIX}-table-head-row`}
              >
                <th
                  id={`${ID_PREFIX}-table-head-empty-cell`}
                  data-pogr-id={`${ID_PREFIX}-table-head-empty-cell`}
                ></th>
                <th
                  id={`${ID_PREFIX}-table-head-pay-as-you-go-cell`}
                  data-pogr-id={`${ID_PREFIX}-table-head-pay-as-you-go-cell`}
                >
                  <div
                    id={`${ID_PREFIX}-table-head-pay-as-you-go-cell-content`}
                    data-pogr-id={`${ID_PREFIX}-table-head-pay-as-you-go-cell-content`}
                    className={styles.thContent}
                  >
                    <FontAwesomeIcon
                      id={`${ID_PREFIX}-table-head-pay-as-you-go-icon`}
                      data-pogr-id={`${ID_PREFIX}-table-head-pay-as-you-go-icon`}
                      icon={faCreditCard}
                      size="xl"
                    />
                    <div
                      id={`${ID_PREFIX}-table-head-pay-as-you-go-text`}
                      data-pogr-id={`${ID_PREFIX}-table-head-pay-as-you-go-text`}
                    >
                      Pay as you go
                    </div>
                  </div>
                </th>
                <th
                  id={`${ID_PREFIX}-table-head-standard-pricing-cell`}
                  data-pogr-id={`${ID_PREFIX}-table-head-standard-pricing-cell`}
                >
                  <div
                    id={`${ID_PREFIX}-table-head-standard-pricing-content`}
                    data-pogr-id={`${ID_PREFIX}-table-head-standard-pricing-content`}
                    className={styles.thContent}
                  >
                    <FontAwesomeIcon
                      id={`${ID_PREFIX}-table-head-standard-pricing-icon`}
                      data-pogr-id={`${ID_PREFIX}-table-head-standard-pricing-icon`}
                      icon={faCoins}
                      size="xl"
                    />
                    <div
                      id={`${ID_PREFIX}-table-head-standard-pricing-text`}
                      data-pogr-id={`${ID_PREFIX}-table-head-standard-pricing-text`}
                    >
                      Standard
                    </div>
                  </div>
                </th>
                <th
                  id={`${ID_PREFIX}-table-head-exterprise-pricing-cell`}
                  data-pogr-id={`${ID_PREFIX}-table-head-exterprise-pricing-cell`}
                >
                  <div
                    id={`${ID_PREFIX}-table-head-exterprise-pricing-content`}
                    data-pogr-id={`${ID_PREFIX}-table-head-exterprise-pricing-content`}
                    className={styles.thContent}
                  >
                    <FontAwesomeIcon
                      id={`${ID_PREFIX}-table-head-exterprise-pricing-icon`}
                      data-pogr-id={`${ID_PREFIX}-table-head-exterprise-pricing-icon`}
                      icon={faTrophy}
                      size="xl"
                    />
                    <div
                      id={`${ID_PREFIX}-table-head-exterprise-pricing-text`}
                      data-pogr-id={`${ID_PREFIX}-table-head-exterprise-pricing-text`}
                    >
                      Enterprise
                    </div>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody
              id={`${ID_PREFIX}-table-body`}
              data-pogr-id={`${ID_PREFIX}-table-body`}
            >
              {pricingData.data.map((pricing, index) => {
                return (
                  <tr
                    key={`${ID_PREFIX}-table-row-${pricing.name.replace(
                      / /g,
                      "-"
                    )}-${index}`}
                    id={`${ID_PREFIX}-table-row-${pricing.name.replace(
                      / /g,
                      "-"
                    )}-container`}
                    data-pogr-id={`${ID_PREFIX}-table-row-${pricing.name.replace(
                      / /g,
                      "-"
                    )}-container`}
                    style={{
                      borderBottom:
                        pricing.name === "Monthly Active Users"
                          ? "1px solid #646480"
                          : "",
                    }}
                  >
                    <td
                      id={`${ID_PREFIX}-table-row-${pricing.name.replace(
                        / /g,
                        "-"
                      )}-title`}
                      data-pogr-id={`${ID_PREFIX}-table-row-${pricing.name.replace(
                        / /g,
                        "-"
                      )}-title`}
                    >
                      {pricing.name}
                    </td>
                    <td
                      id={`${ID_PREFIX}-table-row-${pricing.name.replace(
                        / /g,
                        "-"
                      )}-pay-as-you-go-pricing`}
                      data-pogr-id={`${ID_PREFIX}-table-row-${pricing.name.replace(
                        / /g,
                        "-"
                      )}-pay-as-you-go-pricing`}
                    >
                      {pricing["pay-as-you-go"] === "Coming Soon" ? (
                        <ComingSoonButton />
                      ) : (
                        pricing["pay-as-you-go"]
                      )}
                    </td>
                    <td
                      id={`${ID_PREFIX}-table-row-${pricing.name.replace(
                        / /g,
                        "-"
                      )}-standard-pricing`}
                      data-pogr-id={`${ID_PREFIX}-table-row-${pricing.name.replace(
                        / /g,
                        "-"
                      )}-standard-pricing`}
                    >
                      {pricing.standard === "Coming Soon" ? (
                        <ComingSoonButton />
                      ) : (
                        pricing.standard
                      )}
                    </td>
                    <td
                      id={`${ID_PREFIX}-table-row-${pricing.name.replace(
                        / /g,
                        "-"
                      )}-enterprise-pricing`}
                      data-pogr-id={`${ID_PREFIX}-table-row-${pricing.name.replace(
                        / /g,
                        "-"
                      )}-enterprise-pricing`}
                    >
                      {pricing.enterprise === "Coming Soon" ? (
                        <ComingSoonButton />
                      ) : (
                        pricing.enterprise
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table> */}
        </div>
      </div>
    </Section>
  );
}

export default PricingTableSection;
