import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom';
import Error404Page from '../pages/error/Error404Page';
import HomePage from '../pages/HomePage';
import DeveloperPage from '../pages/DeveloperPage';
import PrivacyPage from '../pages/legal/PrivacyPage';
import TOSPage from '../pages/legal/TOSPage';
import ScrollToTop from './ScrollToTop';
import SDKPage from '../pages/SDKPage';
import PricingPage from '../pages/PricingPage';
import PodcastPage from '../pages/PodcastPage';
import CareersPage from '../pages/CareersPage';
import CareerDetailsPage from '../pages/CareerDetails';
import PodcastDetailsPage from '../pages/PodcastDetailsPage';
import PricingPremiumPage from "../pages/PricingPremiumPage";

function AppNavigator() {
  return (
    <Router>
      <ScrollToTop>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/developer" element={<DeveloperPage />} />
          <Route path="/sdk" element={<SDKPage />} />
          <Route path="/pricing" element={<PricingPage />} />
          <Route path="/pricing/premium" element={<PricingPremiumPage />} />
          <Route path="/podcast" element={<PodcastPage />} />
          <Route path="/podcast/:id" element={<PodcastDetailsPage />} />
          <Route path="/careers" element={<CareersPage />} />
          <Route path="/careers/:id" element={<CareerDetailsPage />} />
          <Route path="/legal/tos" element={<TOSPage />} />
          <Route path="/legal/privacy" element={<PrivacyPage />} />
          <Route path="/404" element={<Error404Page />} />
          <Route path="*" element={<Navigate to="/404" />} />
        </Routes>
      </ScrollToTop>
    </Router>
  );
}

export default AppNavigator;
