import Axios from 'axios';

type POSTMailistArgs = {
  email: string,
  developer: boolean,
  source: string,
  referrer_code?: string | null;
}

export const PostMailistLive = async ({email, developer, source, referrer_code}: POSTMailistArgs) => {
  const data = await Axios({
    method: 'POST',
    url: 'https://marketing.pogr.io/api/v1/mailinglist',
    data: {
      email,
      developer,
      source,
      referrer_code,
    },
  });

  console.log('[Mail]', data);

  return data.data;
};