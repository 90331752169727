import React from "react";
import styles from "./WaitlistRadio.module.scss";

type Props = {
  checked: boolean;
  onClick: (isDev: boolean) => void;
};

const ID_PREFIX = "waitlist-radio";

function WaitlistRadio({ checked, onClick }: Props) {
  return (
    <div
      id={`${ID_PREFIX}-container`}
      data-pogr-id={`${ID_PREFIX}-container`}
      className={styles.middle}
    >
      <label
        id={`${ID_PREFIX}-dev-input-label`}
        data-pogr-id={`${ID_PREFIX}-dev-input-label`}
      >
        <input
          id={`${ID_PREFIX}-dev-radio-input`}
          data-pogr-id={`${ID_PREFIX}-dev-radio-input`}
          type="radio"
          name="radio"
          checked={checked}
          onChange={() => onClick(true)}
        />
        <div
          id={`${ID_PREFIX}-dev-text`}
          data-pogr-id={`${ID_PREFIX}-dev-text`}
          className={`${styles.developer} ${styles.box}`}
        >
          <span
            id={`${ID_PREFIX}-dev-text-span`}
            data-pogr-id={`${ID_PREFIX}-dev-text-span`}
          >
            I'm a developer
          </span>
        </div>
      </label>
      <label
        id={`${ID_PREFIX}-gamer-input-label`}
        data-pogr-id={`${ID_PREFIX}-gamer-input-label`}
      >
        <input
          id={`${ID_PREFIX}-gamer-radio-input`}
          data-pogr-id={`${ID_PREFIX}-gamer-radio-input`}
          type="radio"
          name="radio"
          checked={!checked}
          onChange={() => onClick(false)}
        />
        <div
          id={`${ID_PREFIX}-gamer-text`}
          data-pogr-id={`${ID_PREFIX}-gamer-text`}
          className={`${styles.gamer} ${styles.box}`}
        >
          <span
            id={`${ID_PREFIX}-gamer-text-span`}
            data-pogr-id={`${ID_PREFIX}-gamer-text-span`}
          >
            I'm a gamer
          </span>
        </div>
      </label>
    </div>
  );
}

export default WaitlistRadio;
