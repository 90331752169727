import React from 'react'

type Props = {}

function Error404Page({}: Props) {
  return (
    <div>Error404Page</div>
  )
}

export default Error404Page