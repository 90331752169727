import React from 'react';
import Section from '../../components/layouts/section/Section';
import styles from './TeamSection.module.scss';
import teamlist from './team-members.json';

type TeamPersonProps = {
  id?: string;
  pogrId?: string;
  name: string;
  title: string;
  image: string;
  linkedin?: string;
};

const ID_PREFIX = 'team-section';

const TeamItem = ({
  id,
  pogrId,
  name,
  title,
  image,
  linkedin,
}: TeamPersonProps) => {
  return (
    <div
      id={`${id}-container`}
      data-pogr-id={`${pogrId || id}-container`}
      className={styles.item}
    >
      <div
        id={`${id}-profile-container`}
        data-pogr-id={`${pogrId || id}-profile-container`}
        className={styles.profileSection}
      >
        <img
          id={`${id}-profile-image`}
          data-pogr-id={`${pogrId || id}-profile-image`}
          src={image}
          alt='profile'
        />
      </div>
      <div
        id={`${id}-text-section`}
        data-pogr-id={`${pogrId || id}-text-section`}
        className={styles.textSection}
      >
        <p id={`${id}-name`} data-pogr-id={`${pogrId || id}-name`}>
          <strong>{name}</strong>
        </p>
        <span id={`${id}-title`} data-pogr-id={`${pogrId || id}-title`}>
          {title}
        </span>
        <a
          id={`${id}-linkedin-link`}
          data-pogr-id={`${pogrId || id}-linkedin-link`}
          href={linkedin}
          target='_blank'
          rel='noreferrer'
        >
          <img
            id={`${id}-linkedin-logo`}
            data-pogr-id={`${pogrId || id}-linkedin-logo`}
            src='/static/icons/LinkedIn.svg'
            alt=''
          />
          <span
            id={`${id}-get-in-touch-text`}
            data-pogr-id={`${pogrId || id}-get-in-touch-text`}
          >
            Get in touch
          </span>
        </a>
      </div>
    </div>
  );
};

function TeamSection() {
  return (
    <Section id={`${ID_PREFIX}-main`} pogrId={`${ID_PREFIX}-main`}>
      <div
        id={`${ID_PREFIX}-container`}
        data-pogr-id={`${ID_PREFIX}-container`}
        className={styles.container}
        style={{ backgroundImage: `url("/static/brand/paw.svg")` }}
      >
        <div
          id={`${ID_PREFIX}-text-section`}
          data-pogr-id={`${ID_PREFIX}-text-section`}
          className={styles.textSection}
        >
          <h2
            id={`${ID_PREFIX}-pogr-team-text`}
            data-pogr-id={`${ID_PREFIX}-pogr-team-text`}
          >
            {'The'}
            <img
              src={'/static/pogr-logo-text.svg'}
              alt=''
              className={styles.logoText}
            />
            {'team'}
          </h2>
        </div>
        <div
          id={`${ID_PREFIX}-team-items`}
          data-pogr-id={`${ID_PREFIX}-team-items`}
          className={styles.teamSection}
        >
          {teamlist.map((t, i) => (
            <TeamItem
              key={`team-item=${t.name}-${i}`}
              id={`${ID_PREFIX}-team-item=${t.name}-${i}`}
              data-pogr-id={`${ID_PREFIX}-team-item=${t.name}-${i}`}
              {...t}
            />
          ))}
        </div>
      </div>
    </Section>
  );
}

export default TeamSection;
