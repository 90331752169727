import React from "react";
import styles from "./PodcastLatestEpSection.module.scss";
import Section from "../../components/layouts/section/Section";
import podcastdata from "../../demo-db/podcast/podcast-latest.json";
import PodcastLatestItem from "../../modules/podcast/podcast-latest-item/PodcastLatestItem";

type Props = {};

const ID_PREFIX = "podcast-lastest-ep-section";

function PodcastLatestEpSection({}: Props) {
  return (
    <Section
      id={`${ID_PREFIX}-main`}
      pogrId={`${ID_PREFIX}-main`}
      style={{
        backgroundColor: "#0C3041",
        backgroundImage: 'url("/static/headphones.webp")',
        backgroundPosition: "center center",
        backgroundSize: "cover",
      }}
    >
      <div
        id={`${ID_PREFIX}-content`}
        data-pogr-id={`${ID_PREFIX}-content`}
        className={styles.contentContainer}
      >
        <div
          id={`${ID_PREFIX}-title`}
          data-pogr-id={`${ID_PREFIX}-title`}
          className={styles.title}
        >
          Latest episode
        </div>
        <div
          id={`${ID_PREFIX}-episodes-container`}
          data-pogr-id={`${ID_PREFIX}-episodes-container`}
          className={styles.episodeContainer}
        >
          <PodcastLatestItem episode={podcastdata} />
        </div>
        {/* <div className={styles.iframeContainer}>
          <iframe
            src="https://open.spotify.com/embed/episode/2r7xjNU4i0HR8eOnamCBwg/video?utm_source=generator&theme=0"
            title="latest episode"
            style={{
              width: '600px',
              maxWidth: '100%',
              height: '351px',
              borderRadius: '1rem'
            }}
            allowFullScreen={true}
            allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
            loading="lazy" />
        </div> */}
      </div>
    </Section>
  );
}

export default PodcastLatestEpSection;
