import { Markup } from "interweave";
import React, { useState } from "react";
import Section from "../../../components/layouts/section/Section";
import convertMilliseconds from "../../../helpers/convertTime";
import parseDate from "../../../helpers/parseDate";
import styles from "./PodcastDetailsHeroImageSection.module.scss";

type Props = {
  episode?: any;
};

const ID_PREFIX = "podcast-details-hero-image-section";

function PodcastDetailsHeroImageSection({ episode }: Props) {
  const { id, name, release_date, html_description, duration_ms, images } =
    episode;
  const { timeString } = convertMilliseconds(duration_ms);

  return (
    <Section
      id={`${ID_PREFIX}-main`}
      pogrId={`${ID_PREFIX}-main`}
      style={{ backgroundColor: "#191F30" }}
    >
      <div
        id={`${ID_PREFIX}-container`}
        data-pogr-id={`${ID_PREFIX}-container`}
        className={styles.container}
      >
        <iframe
          id={`${ID_PREFIX}-episode-iframe`}
          data-pogr-id={`${ID_PREFIX}-episode-iframe`}
          title={episode?.name}
          src={`https://open.spotify.com/embed/episode/${id}/video?utm_source=generator&theme=0`}
          allowFullScreen={true}
          allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
          loading="lazy"
        />
        <div
          id={`${ID_PREFIX}-episode-info-wrapper`}
          data-pogr-id={`${ID_PREFIX}-episode-info-wrapper`}
          className={styles.episodeInfoWrapper}
        >
          <div
            id={`${ID_PREFIX}-episode-title`}
            data-pogr-id={`${ID_PREFIX}-episode-title`}
            className={styles.title}
          >
            {name}
          </div>
          <div
            id={`${ID_PREFIX}-episode-duration`}
            data-pogr-id={`${ID_PREFIX}-episode-duration`}
            className={styles.duration}
          >
            {timeString}
          </div>
          <div
            id={`${ID_PREFIX}-episode-published-date`}
            data-pogr-id={`${ID_PREFIX}-episode-published-date`}
            className={styles.publishedDate}
          >
            {parseDate(release_date, "yyyy-MM-dd")}
          </div>
          <div
            id={`${ID_PREFIX}-episode-description`}
            data-pogr-id={`${ID_PREFIX}-episode-description`}
            className={styles.description}
          >
            <Markup content={html_description} />
          </div>
        </div>
      </div>
    </Section>
  );
}

export default PodcastDetailsHeroImageSection;
