import { ReactNode, useEffect } from "react";
import { useLocation } from "react-router";
import { useSearchParams } from "react-router-dom";
import * as Scroll from 'react-scroll';

type Props = {
  children?: ReactNode
}

const ScrollToTop = ({ children }: Props) => {
  const location = useLocation();
  const [searchParams] = useSearchParams();
  useEffect(() => {
    if (searchParams.get('join') !== null) {
      // If join don't scroll
    } else {
      Scroll.animateScroll.scrollToTop({ duration: 300 });
    }
  }, [location]);

  return <>{children}</>
};

export default ScrollToTop;