import { faCaretRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "@mui/material";
import React, { useState } from "react";
import { scroller } from "react-scroll";
import { useSpring, config, animated } from "react-spring";
import HeroImage from "../../../components/layouts/hero-image/HeroImage";
import PricingHeroImage from "../../../components/layouts/hero-image/PricingHeroImage";
import SDKHeroImage from "../../../components/layouts/hero-image/SDKHeroImage";
import Section from "../../../components/layouts/section/Section";
import styles from "../HeroImageSection.module.scss";

type Props = {};

const ID_PREFIX = "pricing-hero-image-section";

function PricingHeroImageSection({}: Props) {
  const [isVisible, setIsVisible] = useState(false);

  const anim = useSpring({
    from: {
      opacity: 0,
      x: -200,
    },
    to: {
      opacity: 1,
      x: 0,
    },
    config: config.default,
  });

  const anim2 = useSpring({
    from: {
      opacity: 0,
      x: -200,
    },
    to: {
      opacity: 1,
      x: 0,
    },
    config: config.default,
    delay: 300,
  });

  const anim3 = useSpring({
    from: {
      opacity: 0,
      x: -200,
    },
    to: {
      opacity: 1,
      x: 0,
    },
    config: config.default,
    delay: 600,
  });

  const anim4 = useSpring({
    from: {
      x: 100,
      opacity: 0,
    },
    to: {
      x: 0,
      opacity: 1,
    },
    config: config.default,
    delay: 1200,
  });

  const anim5 = useSpring({
    from: {
      x: -400,
      opacity: 0,
    },
    to: {
      x: 0,
      opacity: 1,
    },
    delay: 300,
    config: config.default,
  });

  const anim6 = useSpring({
    from: {
      x: -600,
      opacity: 0,
    },
    to: {
      x: 0,
      opacity: 0.3,
    },
    delay: 300,
    config: config.default,
  });

  const handleChange = (visible: boolean) => {
    if (visible) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const handleButtonClick = () => {
    scroller.scrollTo("waitlist", {
      duration: 1000,
      delay: 0,
      smooth: true,
      offset: 100,
    });
  };

  return (
    <Section id={`${ID_PREFIX}-main`} pogrId={`${ID_PREFIX}-main`}>
      <PricingHeroImage />
      <div
        id={`${ID_PREFIX}-text-overlay`}
        data-pogr-id={`${ID_PREFIX}-text-overlay`}
        className={styles.textOverlay}
      >
        <div
          id={`${ID_PREFIX}-text-box`}
          data-pogr-id={`${ID_PREFIX}-text-box`}
          className={styles.textBoxPricing}
        >
          <div
            id={`${ID_PREFIX}-bracket-image-container`}
            data-pogr-id={`${ID_PREFIX}-bracket-image-container`}
            className={styles.bracketImageContainer}
          >
            <animated.img
              style={anim6}
              height="96px"
              className={styles.transparentImage}
              src={"/static/left-curly-bracket.png"}
            />
            <animated.img
              style={anim5}
              height="152px"
              className={styles.originalImage}
              src={"/static/left-curly-bracket.png"}
            />
          </div>
          <div
            id={`${ID_PREFIX}-content`}
            data-pogr-id={`${ID_PREFIX}-content`}
            className={styles.content}
          >
            <animated.h1
              id={`${ID_PREFIX}-animated-secondary-title`}
              data-pogr-id={`${ID_PREFIX}-animated-secondary-title`}
              style={anim2}
              className={styles.secondaryTitle}
            >
              SCALE GAMES <br /> WITH POGR
            </animated.h1>
            <animated.h4
              id={`${ID_PREFIX}-animated-description`}
              data-pogr-id={`${ID_PREFIX}-animated-description`}
              style={anim3}
            >
              To allow small and big studios to get started with POGR, we have
              adapted our plans to scale as your user base grows.
            </animated.h4>
            {/* <animated.div
              id={`${ID_PREFIX}-animated-buttons-container`}
              data-pogr-id={`${ID_PREFIX}-animated-buttons-container`}
              style={anim4}
              className={styles.buttonsContainer}
            >
              <div
                id={`${ID_PREFIX}-buttons-row`}
                data-pogr-id={`${ID_PREFIX}-buttons-row`}
                className={styles.row}
              >
                <Button
                  id={`${ID_PREFIX}-get-started-button`}
                  data-pogr-id={`${ID_PREFIX}-get-started-button`}
                  variant="contained"
                  color="primary"
                  size="large"
                  endIcon={
                    <FontAwesomeIcon icon={faCaretRight} height={8} width={6} />
                  }
                  onClick={handleButtonClick}
                >
                  Get started
                </Button>
                <Button
                  id={`${ID_PREFIX}-book-demo-button`}
                  data-pogr-id={`${ID_PREFIX}-book-demo-button`}
                  variant="outlined"
                  color="primary"
                  size="large"
                  onClick={handleButtonClick}
                  style={{ border: "1px solid #FFFFFF", color: "#FFFFFF" }}
                >
                  Book a demo
                </Button>
              </div>
            </animated.div> */}
          </div>
        </div>
      </div>
    </Section>
  );
}

export default PricingHeroImageSection;
