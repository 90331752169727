import Section from "../../components/layouts/section/Section";
import { useSpring, config, animated, useTrail } from "react-spring";
import careers from "./careers-dummy-data.json";
import styles from "./CareersOpeningsListSection.module.scss";
import { Button, Divider } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretRight } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { CareerType } from "../../types/career";

type Props = {};

const ID_PREFIX = "careers-openings-list-section";

function CareersOpeningsListSection({}: Props) {
  const trail = useTrail((careers as CareerType[]).length ?? 0, {
    config: config.default,
    from: { opacity: 0 },
    to: { opacity: 1 },
  });
  const navigate = useNavigate();
  const handleApplyClick = (opening: any) => {
    console.log("Apply button clicked !");
    navigate(`/careers/${opening.id}`);
  };

  return (
    <Section
      id={`${ID_PREFIX}-main`}
      pogrId={`${ID_PREFIX}-main`}
      style={{ backgroundColor: "#292936" }}
    >
      <div
        id={`${ID_PREFIX}-container`}
        data-pogr-id={`${ID_PREFIX}-container`}
        className={styles.container}
      >
        <div
          id={`${ID_PREFIX}-title-container`}
          data-pogr-id={`${ID_PREFIX}-title-container`}
        >
          <div
            id={`${ID_PREFIX}-title`}
            data-pogr-id={`${ID_PREFIX}-title`}
            className={styles.title}
          >
            Current Openings
          </div>
        </div>
        <div
          id={`${ID_PREFIX}-listings`}
          data-pogr-id={`${ID_PREFIX}-listings`}
          className={styles.listContainer}
        >
          {[].length !== 0 ?
            trail.map(({ ...rest }, trailIndex) => {
              return (
                <animated.div
                  key={`opening-item-${careers[trailIndex]["id"]}`}
                  id={`${ID_PREFIX}-opening-item-${careers[trailIndex]["id"]}`}
                  data-pogr-id={`${ID_PREFIX}-opening-item-${careers[trailIndex]["id"]}`}
                  className={styles.opening}
                  style={{
                    ...rest,
                  }}
                >
                  <div
                    id={`${ID_PREFIX}-opening-item-text-container`}
                    data-pogr-id={`${ID_PREFIX}-opening-item-text-container`}
                    className={styles.textContainer}
                  >
                    <div
                      id={`${ID_PREFIX}-opening-item-${careers[trailIndex]["id"]}-title`}
                      data-pogr-id={`${ID_PREFIX}-opening-item-${careers[trailIndex]["id"]}-title`}
                      className={styles.openingTitle}
                    >
                      {careers[trailIndex]["title"]}
                    </div>
                    <div
                      id={`${ID_PREFIX}-opening-item-type-location-container`}
                      data-pogr-id={`${ID_PREFIX}-opening-item-type-location-container`}
                      className={styles.typeLocationContainer}
                    >
                      <div
                        id={`${ID_PREFIX}-opening-item-${careers[trailIndex]["id"]}-job_type`}
                        data-pogr-id={`${ID_PREFIX}-opening-item-${careers[trailIndex]["id"]}-job_type`}
                        className={styles.openingJobType}
                      >
                        <div className={styles.iconContainer}>
                          <img
                            src={"/static/clock.svg"}
                            alt="Clock Icon for Job Type"
                          />
                        </div>
                        {careers[trailIndex]["job_type"] === "part-time"
                          ? "Part-time"
                          : "Full-time"}
                      </div>
                      <div
                        id={`${ID_PREFIX}-opening-item-${careers[trailIndex]["id"]}-location`}
                        data-pogr-id={`${ID_PREFIX}-opening-item-${careers[trailIndex]["id"]}-location`}
                        className={styles.openingLocation}
                      >
                        <div className={styles.iconContainer}>
                          <img
                            src={"/static/world.svg"}
                            alt="World Icon for position"
                          />
                        </div>
                        {careers[trailIndex]["location"]}
                      </div>
                    </div>
                  </div>
                  <Button
                    id={`${ID_PREFIX}-opening-item-apply-button`}
                    data-pogr-id={`${ID_PREFIX}-opening-item-apply-button`}
                    variant="contained"
                    color="secondary"
                    size="large"
                    className={styles.applyButton}
                    endIcon={
                      <FontAwesomeIcon
                        icon={faCaretRight}
                        height={8}
                        width={6}
                      />
                    }
                    onClick={() => handleApplyClick(careers[trailIndex])}
                  >
                    Apply
                  </Button>
                </animated.div>
              );
            }) :
              <animated.div
                key={`opening-item-no-opening`}
                id={`${ID_PREFIX}-opening-item-no-opening`}
                data-pogr-id={`${ID_PREFIX}-opening-item-no-opening`}
                className={styles.opening}
              >
                <div
                  id={`${ID_PREFIX}-opening-item-text-container`}
                  data-pogr-id={`${ID_PREFIX}-opening-item-text-container`}
                  className={styles.textContainer}
                >
                  <span 
                    id={`${ID_PREFIX}-opening-item-no-opening-text-container`}
                    data-pogr-id={`${ID_PREFIX}-opening-item-no-opening-text-container`}
                    className={styles.noOpeningText}
                  >
                      There are currently no openings available
                  </span>
                </div>
              </animated.div>
            }
        </div>
      </div>
      <Divider className={styles.divider} />
    </Section>
  );
}

export default CareersOpeningsListSection;
