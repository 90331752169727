import React from "react";
import styles from "./PodcastEpisodesListSection.module.scss";
import Section from "../../components/layouts/section/Section";
import PodcastEpisodesList from "../../modules/podcast/podcast-episodes-list/PodcastEpisodesList";
import podcastEpsData from "../../demo-db/podcast/podcast-list.json";

type Props = {};

const ID_PREFIX = "podcast-episodes-list-section";

function PodcastEpisodesListSection({}: Props) {
  const data = podcastEpsData;

  return (
    <Section
      id={`${ID_PREFIX}-main`}
      pogrId={`${ID_PREFIX}-main`}
      style={{ backgroundColor: "#0C3041" }}
    >
      <div
        id={`${ID_PREFIX}-content`}
        data-pogr-id={`${ID_PREFIX}-content`}
        className={styles.contentContainer}
      >
        <div
          id={`${ID_PREFIX}-title`}
          data-pogr-id={`${ID_PREFIX}-title`}
          className={styles.title}
        >
          All episodes
        </div>
        <div
          id={`${ID_PREFIX}-list-container`}
          data-pogr-id={`${ID_PREFIX}-list-container`}
          className={styles.listContainer}
        >
          <PodcastEpisodesList episodes={data?.items} />
        </div>
      </div>
    </Section>
  );
}

export default PodcastEpisodesListSection;
