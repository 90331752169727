import React from "react";
import Page from "../components/layouts/page/Page";
import PodcastHeroImageSection from "../sections/hero-image-section/podcast/PodcastHeroImageSection";
import PodcastEpisodesListSection from "../sections/podcast-episodes-list-section/PodcastEpisodesListSection";
import PodcastInsightsSection from "../sections/podcast-insights-section/PodcastInsightsSection";
import PodcastLatestEpSection from "../sections/podcast-latest-ep-section/PodcastLatestEpSection";

type Props = {};

function PodcastPage({}: Props) {
  return (
    <Page>
      <PodcastHeroImageSection />
      <PodcastLatestEpSection />
      <PodcastEpisodesListSection />
      <PodcastInsightsSection />
    </Page>
  );
}

export default PodcastPage;
