import React, { useState } from 'react';
import { useSpring, animated, config } from 'react-spring';
import { scroller } from 'react-scroll';
import IntersectionObserverWrapper from '../../../components/intersection-observer-wrapper/IntersectionObserverWrapper';
import DevHeroImage from '../../../components/layouts/hero-image/DevHeroImage';
import Section from '../../../components/layouts/section/Section';
import styles from './DevHeroImageSection.module.scss';
import { Button } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretRight } from '@fortawesome/free-solid-svg-icons';

function DevHeroImageSection() {
  const [isVisible, setIsVisible] = useState(false);
  const anim = useSpring({
    from: {
      opacity: 0,
      x: -200,
    },
    to: {
      opacity: 1,
      x: 0,
    },
    config: config.default,
  });

  const anim2 = useSpring({
    from: {
      opacity: 0,
      x: -200,
    },
    to: {
      opacity: 1,
      x: 0,
    },
    config: config.default,
    delay: 300,
  });

  const anim3 = useSpring({
    from: {
      opacity: 0,
      x: -200,
    },
    to: {
      opacity: 1,
      x: 0,
    },
    config: config.default,
    delay: 600,
  });

  const anim4 = useSpring({
    from: {
      x: 100,
      opacity: 0,
    },
    to: {
      x: 0,
      opacity: 1,
    },
    config: config.default,
    delay: 1200,
  });

  const handleChange = (visible: boolean) => {
    if (visible) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const handleButtonClick = () => {
    scroller.scrollTo('waitlist', {
      duration: 1000,
      delay: 0,
      smooth: true,
      offset: 100,
    });
  };

  return (
    <IntersectionObserverWrapper onChange={handleChange}>
      <Section>
        <DevHeroImage
          vidSrc={'/static/hero-images/dev2.mp4'}
          imgSrc={'/static/hero-images/dev.webp'}
        />
        <div className={styles.textOverlay}>
          <div className={styles.textBox}>
            <animated.div style={anim} className={styles.bubble}>
              <h4 style={{ color: '#FF9C41' }}>Tailored for developers</h4>
            </animated.div>
            <animated.h1 style={anim2}>
              DEEP DIVE INTO <br /> YOUR GAME
            </animated.h1>
            <animated.h4 style={anim3}>
              We allow game developers to access data that usually isn’t
              available. We enable developers to decide what data is being
              collected and the ability to visualize it how they want.
            </animated.h4>
            <animated.div style={anim4}>
              <Button
                variant='contained'
                color='primary'
                size='large'
                endIcon={
                  <FontAwesomeIcon icon={faCaretRight} height={8} width={6} />
                }
                onClick={handleButtonClick}
              >
                Join POGR
              </Button>
            </animated.div>
          </div>
        </div>
      </Section>
    </IntersectionObserverWrapper>
  );
}

export default DevHeroImageSection;
