import { useSpring, config, animated } from "react-spring";
import CareersHeroImage from "../../../components/layouts/hero-image/CareersHeroImage";
import Section from "../../../components/layouts/section/Section";
import styles from "../HeroImageSection.module.scss";

type Props = {};

const ID_PREFIX = "careers-hero-image-section";

function CareersHeroImageSection({}: Props) {
  const animTitle = useSpring({
    from: {
      opacity: 0,
      y: 200,
    },
    to: {
      opacity: 1,
      y: 0,
    },
    delay: 200,
    config: config.default,
  });

  const animDescription = useSpring({
    from: {
      opacity: 0,
      y: 200,
    },
    to: {
      opacity: 1,
      y: 0,
    },
    delay: 400,
    config: config.default,
  });

  return (
    <Section id={`${ID_PREFIX}-main`} pogrId={`${ID_PREFIX}-main`}>
      <CareersHeroImage />
      <div
        id={`${ID_PREFIX}-text-overlay`}
        data-pogr-id={`${ID_PREFIX}-text-overlay`}
        className={styles.textOverlayForCareers}
      >
        <div
          id={`${ID_PREFIX}-content`}
          data-pogr-id={`${ID_PREFIX}-content`}
          className={styles.content}
        >
          <animated.h1
            id={`${ID_PREFIX}-title-animated`}
            data-pogr-id={`${ID_PREFIX}-title-animated`}
            style={animTitle}
            className={styles.title}
          >
            Help us shape a better gaming community
          </animated.h1>
          <animated.h4
            id={`${ID_PREFIX}-description-animated`}
            data-pogr-id={`${ID_PREFIX}-description-animated`}
            style={animDescription}
            className={styles.description}
          >
            We firmly believe that POGR is the cornerstone of a thriving gaming
            community, and we are constantly on the lookout for talented
            individuals to join our passionate team.
          </animated.h4>
        </div>
      </div>
    </Section>
  );
}

export default CareersHeroImageSection;
