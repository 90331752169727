import React from "react";
import styles from "./CareersHeroImage.module.scss";

type Props = {
  vidSrc?: string;
  imgSrc?: string;
};

const ID_PREFIX = "careers-hero-image";

function CareersHeroImage({ vidSrc, imgSrc }: Props) {
  return (
    <div
      id={`${ID_PREFIX}-container`}
      data-pogr-id={`${ID_PREFIX}-container`}
      className={styles.container}
    >
      <img
        id={`${ID_PREFIX}-image`}
        data-pogr-id={`${ID_PREFIX}-image`}
        src={"/static/hero-images/careers.webp"}
        alt="hero"
      />
      <div
        id={`${ID_PREFIX}-top-gradient`}
        data-pogr-id={`${ID_PREFIX}-top-gradient`}
        className={styles.topGradient}
      />
      <div
        id={`${ID_PREFIX}-bottom-gradient`}
        data-pogr-id={`${ID_PREFIX}-bottom-gradient`}
        className={styles.bottomGradient}
      />
    </div>
  );
}

export default CareersHeroImage;
