import Page from "../components/layouts/page/Page";
import PricingPremiumHeroImageSection from "../sections/hero-image-section/pricing-premium/PricingPremiumHeroImageSection";
import PricingPremiumFeaturesSection from "../sections/pricing-premium-features-section/PricingPremiumFeaturesSection";
import WaitlistSection from "../sections/waitlist-section/WaitlistSection";

type Props = {};

const PricingPremiumPage = ({}: Props) => {
  return (
    <Page>
      <PricingPremiumHeroImageSection />
      <PricingPremiumFeaturesSection />
    </Page>
  );
};

export default PricingPremiumPage;
