import { createTheme, Theme } from '@mui/material';

const colors = {
  themeGrayBlue1: '#8484A5',
  themeGrayBlue100: '#1A1A22',
  themeGrayBlue200: '#292936',
  themeGrayBlue250: '#2F2F3D',
  themeGrayBlue300: '#343444',
  themeGrayBlue350: '#3E3E50',
  themeGrayBlue400: '#4A4A5F',
  themeGrayBlue450: '#5C5C78',
  themeGrayBlue500: '#646480',
  themeGrayBlue600: '#8484A5',
  themeGrayBlue700: '#9D9DBB',
  themeGrayBlue800: '#B7B7D4',
  themeBackgroundColor: '#646480',
  themeCyan: '#00DDDD',
  themeOrange: '#FF9C41',
  themeSuccessColor: '#4ECE5B',
  themeErrorColor: '#FD4466',
};

const breakpoints = {
  xs: 0,
  sm: 576,
  md: 996,
  lg: 1600,
  xl: 2600,
};

const useMuiTheme: Theme = createTheme({
  palette: {
    text: {
      primary: '#FFF',
      secondary: colors.themeGrayBlue100,
    },
    primary: {
      main: colors.themeCyan,
    },
    secondary: {
      main: colors.themeOrange,
    },
    success: {
      main: colors.themeSuccessColor,
    },
    error: {
      main: colors.themeErrorColor,
    },
  },
  typography: {
    allVariants: {
      fontFamily: "'Ubuntu', sans-serif",
      color: '#FFF',
    },
    h2: {
      fontSize: '1.375rem',
      fontWeight: '700',
      lineHeight: '1.5rem',
      letterSpacing: '-1px',
      [`@media (min-width:${breakpoints.md})`]: {
        fontSize: '1.875rem',
        lineHeight: '2.5rem',
      },
    },
    body1: {
      fontSize: '0.8125rem',
      fontWeight: '700',
      lineHeight: '1rem',
      letterSpacing: '-0.1px',
      [`@media (min-width:${breakpoints.md})`]: {
        fontSize: '0.875rem',
        lineHeight: '1.25rem',
      },
    },
    button: {
      fontSize: '1rem',
      fontWeight: 700,
      letterSpacing: '-0.3px',
      textTransform: 'none',
    },
  },
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: colors.themeGrayBlue350,
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          fontSize: '150%',
          minHeight: '0',
        },
        icon: {
          color: '#FFF',
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          display: 'flex',
          width: '260px',
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          backgroundColor: 'transparent',
        },
        list: {
          backgroundColor: '#3E3E50',
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: {},
        thumb: {
          backgroundColor: '#FFF',
        },
        track: {
          '.Mui-checked.Mui-checked + &': {
            opacity: 1,
          },
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          color: '#FFF',
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: '#FFF',
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {},
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '&:hover .MuiOutlinedInput-notchedOutline': {
            border: '1px solid #FFF',
          },
        },
        input: {
          '&::placeholder': {
            color: colors.themeGrayBlue500,
            opacity: 1,
          },
        },
        notchedOutline: {
          borderColor: '#646480',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {},
        input: {},
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {},
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          color: colors.themeGrayBlue700,
          '&.Mui-selected': {
            color: 'white',
          },
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        root: {},
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          ':focus': {
            outlineWidth: '1px',
          },
          ':disabled': {
            opacity: 0.4,
          },
          transitionDuration: '200ms',
          borderRadius: '0.5rem',
        },
        startIcon: {
          margin: 0,
          paddingRight: '0.5rem',
        },
        sizeSmall: {
          padding: '0.5rem 0.75rem',
          borderRadius: '1.5rem',
        },
        sizeMedium: {
          borderRadius: '1.5rem',
        },
        text: {
          borderRadius: 0,
        },
        textPrimary: {
          color: colors.themeGrayBlue700,
        },
        containedPrimary: {
          backgroundColor: 'white',
          color: colors.themeGrayBlue100,
          ':hover': {
            backgroundColor: colors.themeGrayBlue300,
            color: 'white',
          },
          ':disabled': {
            backgroundColor: 'white',
            color: colors.themeGrayBlue100,
          },
        },
        containedSecondary: {
          backgroundColor: colors.themeGrayBlue300,
          color: 'white',
          ':hover': {
            backgroundColor: colors.themeGrayBlue300,
            color: 'white',
          },
        },
        containedInfo: {
          backgroundColor: colors.themeCyan,
          color: 'black',
          ':hover': {
            backgroundColor: colors.themeOrange,
            color: 'black',
          },
        },
      },
    },
  },
});

export default useMuiTheme;
