import React, { useEffect } from 'react'
import ReactGA from 'react-ga';
import { useSearchParams } from 'react-router-dom';
import { scroller } from 'react-scroll';
import Page from '../components/layouts/page/Page'
import FeaturesSection from '../sections/features-section/FeaturesSection'
import GateSection from '../sections/gate-section/GateSection'
import DevHeroImageSection from '../sections/hero-image-section/developer/DevHeroImageSection'
import MainBlurbSection from '../sections/main-blurb-section/MainBlurbSection'
import TeamSection from '../sections/team-section/TeamSection'
import WaitlistSection from '../sections/waitlist-section/WaitlistSection'
import DevWidgetSection from '../sections/widget-section/DevWidgetSection';

type Props = {}

function DeveloperPage({}: Props) {
  let [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
    if (searchParams.get('join') !== null) {
      scroller.scrollTo('waitlist', {
        offset: 100
      });
    }
  }, []);

  return (
    <Page>
      <DevHeroImageSection />
      <MainBlurbSection page='dev' />
      <FeaturesSection page="dev" />
      <DevWidgetSection />
      <GateSection />
      <TeamSection />
      <WaitlistSection />
    </Page>
  )
}

export default DeveloperPage