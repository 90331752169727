import React from 'react'
import styles from './DevHeroImage.module.scss';

type Props = {
  vidSrc?: string;
  imgSrc?: string;
}

function DevHeroImage({ vidSrc, imgSrc }: Props) {
  return (
    <div className={styles.container}>
      <video
        className="videoTag"
        preload="auto"
        autoPlay
        controls={false}
        loop
        muted
        playsInline
        webkit-playsinline="true"
        poster='/static/hero-images/dev.webp'
      >
        <source src={vidSrc || '/static/hero-images/dev.mp4'} type="video/mp4" />
        <img src={imgSrc || '/static/hero-images/dev.webp'} alt="hero" />
      </video>
      <div className={styles.topGradient} />
      <div className={styles.bottomGradient} />
    </div>
  )
}

export default DevHeroImage