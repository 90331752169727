import React from 'react'
import { useParams } from 'react-router-dom'
import Page from '../components/layouts/page/Page'
import PodcastDetailsHeroImageSection from '../sections/hero-image-section/podcast/PodcastDetailsHeroImageSection'
import PodcastEpisodesListSection from '../sections/podcast-episodes-list-section/PodcastEpisodesListSection'
import podcastEpsData from '../demo-db/podcast/podcast-list.json'
import PodcastInsightsSection from '../sections/podcast-insights-section/PodcastInsightsSection'

type Props = {}

function PodcastDetailsPage({}: Props) {
  const { id } = useParams();
  const data = podcastEpsData;
  const episode = data.items.find((ep) => ep.id === id);

  return (
    <Page>
      <PodcastDetailsHeroImageSection episode={episode} />
      <PodcastEpisodesListSection />
      <PodcastInsightsSection />
    </Page>
  )
}

export default PodcastDetailsPage