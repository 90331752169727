import Section from "../../components/layouts/section/Section";
import styles from "./PricingPremiumFeaturesSection.module.scss";
import pricingPremiumFeatures from "../../demo-db/pricing/pricing-premium-features.json";
import { useSpring, config, animated } from "react-spring";

type Props = {};

const ID_PREFIX = "pricing-premium-features-section";

const PricingPremiumFeaturesSection = ({}: Props) => {
  const anim = useSpring({
    from: {
      opacity: 0,
      x: -200,
    },
    to: {
      opacity: 1,
      x: 0,
    },
    delay: 200,
    config: config.default,
  });
  const anim1 = useSpring({
    from: {
      opacity: 0,
      x: 200,
    },
    to: {
      opacity: 1,
      x: 0,
    },
    delay: 500,
    config: config.default,
  });
  return (
    <Section
      id={`${ID_PREFIX}-main`}
      pogrId={`${ID_PREFIX}-main`}
      style={{
        background: "linear-gradient(180deg, #151A2A 0%, #222739 100%)",
      }}
    >
      <div
        id={`${ID_PREFIX}-container`}
        data-pogr-id={`${ID_PREFIX}-container`}
        className={styles.container}
      >
        <span className={styles.containerTitle}>WHAT'S INCLUDED</span>
        <div
          id={`${ID_PREFIX}-content`}
          data-pogr-id={`${ID_PREFIX}-content`}
          className={styles.content}
        >
          {pricingPremiumFeatures.data.map((feature, index) => {
            return (
              <div
                key={`${ID_PREFIX}-${feature.title.replace(
                  / /g,
                  "-"
                )}-${index}`}
                id={`${ID_PREFIX}-${feature.title.replace(
                  / /g,
                  "-"
                )}-container`}
                data-pogr-id={`${ID_PREFIX}-${feature.title.replace(
                  / /g,
                  "-"
                )}-container`}
                className={styles.featureRow}
              >
                <animated.div
                  id={`${ID_PREFIX}-${feature.title.replace(
                    / /g,
                    "-"
                  )}-text-container`}
                  data-pogr-id={`${ID_PREFIX}-${feature.title.replace(
                    / /g,
                    "-"
                  )}-text-container`}
                  className={styles.textContainer}
                  style={anim}
                >
                  <div
                    id={`${ID_PREFIX}-${feature.title.replace(
                      / /g,
                      "-"
                    )}-title`}
                    data-pogr-id={`${ID_PREFIX}-${feature.title.replace(
                      / /g,
                      "-"
                    )}-title`}
                    className={styles.title}
                  >
                    {feature.title}
                  </div>
                  <div
                    id={`${ID_PREFIX}-${feature.title.replace(
                      / /g,
                      "-"
                    )}-description`}
                    data-pogr-id={`${ID_PREFIX}-${feature.title.replace(
                      / /g,
                      "-"
                    )}-description`}
                    className={styles.description}
                  >
                    {feature.description}
                  </div>
                </animated.div>
                <div
                  id={`${ID_PREFIX}-${feature.title.replace(
                    / /g,
                    "-"
                  )}-image-animated-container`}
                  data-pogr-id={`${ID_PREFIX}-${feature.title.replace(
                    / /g,
                    "-"
                  )}-image-animated-container`}
                  className={styles.imageWrapper}
                >
                  <animated.img
                    id={`${ID_PREFIX}-${feature.title.replace(
                      / /g,
                      "-"
                    )}-image`}
                    data-pogr-id={`${ID_PREFIX}-${feature.title.replace(
                      / /g,
                      "-"
                    )}-image`}
                    src={"/static/pricing-premium-widgets.png"}
                    alt="widgets"
                    style={anim1}
                  />
                </div>
              </div>
            );
          })}
          {/* <div
            id={`${ID_PREFIX}-widgets-container`}
            data-pogr-id={`${ID_PREFIX}-widgets-container`}
            className={styles.featureRow}
          >
            <div
              id={`${ID_PREFIX}-widgets-text-container`}
              data-pogr-id={`${ID_PREFIX}-widgets-text-container`}
              className={styles.textContainer}
            >
              <div
                id={`${ID_PREFIX}-widgets-title`}
                data-pogr-id={`${ID_PREFIX}-widgets-title`}
                className={styles.title}
              >
                Widgets 2.0
              </div>
              <div
                id={`${ID_PREFIX}-widgets-description`}
                data-pogr-id={`${ID_PREFIX}-widgets-description`}
                className={styles.description}
              >
                Unlock new widgets and personalize them even more with
                high-quality skins and custom colors.
              </div>
            </div>
            <div
              id={`${ID_PREFIX}-widgets-image-animated-container`}
              data-pogr-id={`${ID_PREFIX}-widgets-image-animated-container`}
              className={styles.imageWrapper}
            >
              <img
                id={`${ID_PREFIX}-widgets-image`}
                data-pogr-id={`${ID_PREFIX}-widgets-image`}
                src={"/static/pricing-premium-widgets.png"}
                alt="widgets"
                style={{ objectFit: "cover", width: "100%" }}
              />
            </div>
          </div>
          <div
            id={`${ID_PREFIX}-stats-container`}
            data-pogr-id={`${ID_PREFIX}-stats-container`}
            className={styles.featureRow}
          >
            <div
              id={`${ID_PREFIX}-stats-text-container`}
              data-pogr-id={`${ID_PREFIX}-stats-text-container`}
              className={styles.textContainer}
            >
              <div
                id={`${ID_PREFIX}-stats-title`}
                data-pogr-id={`${ID_PREFIX}-stats-title`}
                className={styles.title}
              >
                Hourly stats update
              </div>
              <div
                id={`${ID_PREFIX}-stats-description`}
                data-pogr-id={`${ID_PREFIX}-stats-description`}
                className={styles.description}
              >
                Keep your profile up-to-date by reporting your game statistics
                more frequently.
              </div>
            </div>
            <div
              id={`${ID_PREFIX}-stats-image-animated-container`}
              data-pogr-id={`${ID_PREFIX}-stats-image-animated-container`}
              className={styles.imageWrapper}
            >
              <img
                id={`${ID_PREFIX}-stats-image`}
                data-pogr-id={`${ID_PREFIX}-stats-image`}
                src={"/static/pricing-premium-widgets.png"}
                alt="stats"
                style={{ objectFit: "cover", width: "100%" }}
              />
            </div>
          </div>
          <div
            id={`${ID_PREFIX}-selling-point-container`}
            data-pogr-id={`${ID_PREFIX}-selling-point-container`}
            className={styles.featureRow}
          >
            <div
              id={`${ID_PREFIX}-selling-point-text-container`}
              data-pogr-id={`${ID_PREFIX}-selling-point-text-container`}
              className={styles.textContainer}
            >
              <div
                id={`${ID_PREFIX}-selling-point-title`}
                data-pogr-id={`${ID_PREFIX}-selling-point-title`}
                className={styles.title}
              >
                Selling point #3
              </div>
              <div
                id={`${ID_PREFIX}-selling-point-description`}
                data-pogr-id={`${ID_PREFIX}-selling-point-description`}
                className={styles.description}
              >
                Some description here to the third and probably last included
                feature in POGR premium.
              </div>
            </div>
            <div
              id={`${ID_PREFIX}-selling-point-image-animated-container`}
              data-pogr-id={`${ID_PREFIX}-selling-point-image-animated-container`}
              className={styles.imageWrapper}
            >
              <img
                id={`${ID_PREFIX}-selling-point-image`}
                data-pogr-id={`${ID_PREFIX}-selling-point-image`}
                src={"/static/pricing-premium-widgets.png"}
                alt="selling-point-3"
                style={{ objectFit: "cover", width: "100%" }}
              />
            </div>
          </div> */}
        </div>
      </div>
    </Section>
  );
};

export default PricingPremiumFeaturesSection;
