import React, { useState } from 'react';
import IntersectionObserverWrapper from '../../components/intersection-observer-wrapper/IntersectionObserverWrapper';
import Section from '../../components/layouts/section/Section';
import styles from './DevWidgetSection.module.scss';

const ID_PREFIX = 'dev-widget-section';

function DevWidgetSection() {
  const [isVisible, setIsVisible] = useState(false);

  const handleChange = (visible: boolean) => {
    if (visible) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };
  return (
    <Section id={`${ID_PREFIX}-main`} pogrId={`${ID_PREFIX}-main`}>
      <div
        id={`${ID_PREFIX}-container`}
        data-pogr-id={`${ID_PREFIX}-container`}
        className={styles.container}
      >
        <div
          id={`${ID_PREFIX}-text-section`}
          data-pogr-id={`${ID_PREFIX}-text-section`}
          className={`${styles.textSection} ${
            isVisible ? styles.visible : styles.notvisible
          }`}
        >
          <IntersectionObserverWrapper
            id={`${ID_PREFIX}-intersection-observer`}
            pogrId={`${ID_PREFIX}-intersection-observer`}
            onChange={handleChange}
          >
            <h2 id={`${ID_PREFIX}-title`} data-pogr-id={`${ID_PREFIX}-title`}>
              <strong>Simplify & Scale!</strong>
            </h2>
          </IntersectionObserverWrapper>
          <br />
          <p
            id={`${ID_PREFIX}-description`}
            data-pogr-id={`${ID_PREFIX}-description`}
          >
            POGR is here to empower game developers by allowing them to
            understand and reach players in a way that previously was never
            thought possible. We made it simple for developers and
            non-developers alike to make informed decisions about their game:
            where are players dropping off during a campaign/quest line? What
            are the best ways to monetize your players so they feel valued
            authentically?
          </p>
        </div>
        <div
          id={`${ID_PREFIX}-widgets-container`}
          data-pogr-id={`${ID_PREFIX}-widgets-container`}
          className={styles.widgetSection}
        >
          <img
            id={`${ID_PREFIX}-dev-widget-image`}
            data-pogr-id={`${ID_PREFIX}-dev-widget-image`}
            width={'100%'}
            src={'/static/dev-widget-screenshot.webp'}
            alt=''
          />
          {/* <Widget />
            <Widget />
            <Widget />
            <Widget />
            <Widget />
            <Widget /> */}
        </div>
      </div>
    </Section>
  );
}

export default DevWidgetSection;
