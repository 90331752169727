import React from 'react'
import Page from '../components/layouts/page/Page'
import PricingHeroImageSection from '../sections/hero-image-section/pricing/PricingHeroImageSection'
import PricingFeaturesSection from '../sections/pricing-features-section/PricingFeaturesSection'
import PricingTableSection from '../sections/pricing-table-section/PricingTableSection'
import WaitlistSection from '../sections/waitlist-section/WaitlistSection'

type Props = {}

function PricingPage({}: Props) {
  return (
    <Page>
      <PricingHeroImageSection />
      <PricingTableSection />
      <PricingFeaturesSection />
      <WaitlistSection />
    </Page>
  )
}

export default PricingPage