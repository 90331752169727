import React from "react";
import Page from "../components/layouts/page/Page";
import AboutPogr from "../sections/about-pogr-section/AboutPogr";
import CareersOpeningsListSection from "../sections/careers-openings-list-section/CareersOpeningsListSection";
import CareersHeroImageSection from "../sections/hero-image-section/careers/CareersHeroImageSection";
import MainBlurbSection from "../sections/main-blurb-section/MainBlurbSection";

type Props = {};

function CareersPage({}: Props) {
  return (
    <Page>
      <CareersHeroImageSection />
      <MainBlurbSection page="careers" />
      <CareersOpeningsListSection />
      <AboutPogr />
    </Page>
  );
}

export default CareersPage;
