import React, { ReactNode } from 'react'
import styles from './Section.module.scss';

type Props = {
  id?: string;
  pogrId?: string;
  children?: ReactNode;
  style?: React.CSSProperties;
}

function Section({ id, pogrId, children, style }: Props) {
  return (
    <section
    id={`${id}`}
    data-pogr-id={`${pogrId||id}`}
     style={style} className={styles.section}>
      {children}
    </section>
  )
}

export default Section