import Section from "../../components/layouts/section/Section";
import { useSpring, config, animated, useSpringRef } from "react-spring";
import styles from "./CareerApplySection.module.scss";
import { Button, Divider, TextField } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretRight } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

type Props = {};

const ID_PREFIX = "career-apply-section";

function CareerApplySection({}: Props) {
  const [resumeFileName, setResumeFileName] = useState<string | null>(null);
  const [coverLetterFileName, setCoverLetterFileName] = useState<string | null>(
    null
  );

  const handleApply = () => {
    console.log("Apply using LinkedIn button clicked !");
  };
  const handleUploadResume = (event: React.ChangeEvent<HTMLInputElement>) => {
    console.log("Resume upload button clicked !!!");
    console.log(event.target.files);
    console.log("Resume File name: ", event.target.files![0].name);
    setResumeFileName(event.target.files![0].name);
  };

  const handleResetResume = () => {
    setResumeFileName(null);
  };

  const handleUploadCoverLetter = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    console.log("Cover letter upload button clicked !!!");
    console.log(event.target.files);
    console.log("Cover letter File name: ", event.target.files![0].name);
    setCoverLetterFileName(event.target.files![0].name);
  };

  const handleResetCoverLetter = () => {
    setCoverLetterFileName(null);
  };

  return (
    <Section
      id={`${ID_PREFIX}-main`}
      pogrId={`${ID_PREFIX}-main`}
      style={{ backgroundColor: "#292936" }}
    >
      <div
        id={`${ID_PREFIX}-container`}
        data-pogr-id={`${ID_PREFIX}-container`}
        className={styles.container}
      >
        <div
          id={`${ID_PREFIX}-title-container`}
          data-pogr-id={`${ID_PREFIX}-title-container`}
        >
          <div
            id={`${ID_PREFIX}-title`}
            data-pogr-id={`${ID_PREFIX}-title`}
            className={styles.title}
          >
            Sounds interesting? Apply now!
          </div>
        </div>
        <div
          id={`${ID_PREFIX}-content`}
          data-pogr-id={`${ID_PREFIX}-content`}
          className={styles.content}
        >
          <div
            id={`${ID_PREFIX}-apply-with-linkedin-button-container`}
            data-pogr-id={`${ID_PREFIX}-apply-with-linkedin-button-container`}
          >
            <Button
              id={`${ID_PREFIX}-apply-with-linkedin-button`}
              data-pogr-id={`${ID_PREFIX}-apply-with-linkedin-button`}
              variant="contained"
              color="secondary"
              size="medium"
              startIcon={
                <div className={styles.iconContainer}>
                  <img
                    src={"/static/linkedin-black-white.svg"}
                    alt="Linkedin apply button"
                  />
                </div>
              }
              className={styles.applyButton}
              onClick={handleApply}
            >
              Apply using LinkedIn
            </Button>
          </div>
          <form
            id={`${ID_PREFIX}-form-container`}
            data-pogr-id={`${ID_PREFIX}-form-container`}
            className={styles.formContainer}
          >
            <div
              id={`${ID_PREFIX}-first-name-input-container`}
              data-pogr-id={`${ID_PREFIX}-first-name-input-container`}
              className={styles.textFieldInputContainer}
            >
              <label
                id={`${ID_PREFIX}-first-name-input-label`}
                data-pogr-id={`${ID_PREFIX}-first-name-input-label`}
                className={styles.label}
              >
                First name
              </label>
              <TextField
                id={`${ID_PREFIX}-first-name-input`}
                data-pogr-id={`${ID_PREFIX}-first-name-input`}
                variant="outlined"
                className={styles.textFieldInput}
                placeholder="John..."
              />
            </div>
            <div
              id={`${ID_PREFIX}-last-name-input-container`}
              data-pogr-id={`${ID_PREFIX}-last-name-input-container`}
              className={styles.textFieldInputContainer}
            >
              <label
                id={`${ID_PREFIX}-last-name-input-label`}
                data-pogr-id={`${ID_PREFIX}-last-name-input-label`}
                className={styles.label}
              >
                Last name
              </label>
              <TextField
                id={`${ID_PREFIX}-last-name-input`}
                data-pogr-id={`${ID_PREFIX}-last-name-input`}
                variant="outlined"
                className={styles.textFieldInput}
                placeholder="Smith..."
              />
            </div>
            <div
              id={`${ID_PREFIX}-phone-input-container`}
              data-pogr-id={`${ID_PREFIX}-phone-input-container`}
              className={styles.textFieldInputContainer}
            >
              <label
                id={`${ID_PREFIX}-phone-input-label`}
                data-pogr-id={`${ID_PREFIX}-phone-input-label`}
                className={styles.label}
              >
                Phone Number
              </label>
              <TextField
                id={`${ID_PREFIX}-phone-input`}
                data-pogr-id={`${ID_PREFIX}-phone-input`}
                variant="outlined"
                className={styles.textFieldInput}
                type={"tel"}
                inputProps={{
                  pattern: "[0-9]{3}-[0-9]{2}-[0-9]{3}",
                }}
                placeholder="+123..."
              />
            </div>
            <div
              id={`${ID_PREFIX}-email-input-container`}
              data-pogr-id={`${ID_PREFIX}-email-input-container`}
              className={styles.textFieldInputContainer}
            >
              <label
                id={`${ID_PREFIX}-email-input-label`}
                data-pogr-id={`${ID_PREFIX}-email-input-label`}
                className={styles.label}
              >
                Email
              </label>
              <TextField
                id={`${ID_PREFIX}-email-input`}
                data-pogr-id={`${ID_PREFIX}-email-input`}
                variant="outlined"
                className={styles.textFieldInput}
                type={"email"}
                placeholder="you@yourmail.com"
              />
            </div>
            <div
              id={`${ID_PREFIX}-resume-button-container`}
              data-pogr-id={`${ID_PREFIX}-resume-button-container`}
              className={styles.resumeButtonContainer}
            >
              <span className={styles.resumeTitle}>Attach resume</span>
              {resumeFileName && (
                <Button
                  id={`${ID_PREFIX}-uploaded-resume-button`}
                  data-pogr-id={`${ID_PREFIX}-uploaded-resume-button`}
                  variant="contained"
                  color="secondary"
                  size="medium"
                  component="label"
                  startIcon={
                    <>
                      <div className={styles.iconContainer}>
                        <img src={"/static/close.svg"} alt="Close icon" />
                      </div>
                    </>
                  }
                  className={styles.uploadedResumeButton}
                  onClick={handleResetResume}
                >
                  {resumeFileName}
                </Button>
              )}
              {!resumeFileName && (
                <Button
                  id={`${ID_PREFIX}-resume-button`}
                  data-pogr-id={`${ID_PREFIX}-resume-button`}
                  variant="contained"
                  color="secondary"
                  size="medium"
                  component="label"
                  startIcon={
                    <>
                      <div className={styles.iconContainer}>
                        <img
                          src={"/static/upload.svg"}
                          alt="Upload resume icon"
                        />
                      </div>
                    </>
                  }
                  className={styles.resumeButton}
                >
                  {"Upload File"}
                  <input
                    id={`${ID_PREFIX}-resume-file-input-hidden`}
                    data-pogr-id={`${ID_PREFIX}-resume-file-input-hidden`}
                    type="file"
                    hidden
                    onChange={handleUploadResume}
                  />
                </Button>
              )}
            </div>
            <div
              id={`${ID_PREFIX}-cover-letter-button-container`}
              data-pogr-id={`${ID_PREFIX}-cover-letter-button-container`}
              className={styles.resumeButtonContainer}
            >
              <span className={styles.resumeTitle}>Attach cover letter</span>
              {coverLetterFileName && (
                <Button
                  id={`${ID_PREFIX}-uploaded-cover-letter-button`}
                  data-pogr-id={`${ID_PREFIX}-uploaded-cover-letter-button`}
                  variant="contained"
                  color="secondary"
                  size="medium"
                  component="label"
                  startIcon={
                    <>
                      <div className={styles.iconContainer}>
                        <img src={"/static/close.svg"} alt="Close icon" />
                      </div>
                    </>
                  }
                  className={styles.uploadedResumeButton}
                  onClick={handleResetCoverLetter}
                >
                  {coverLetterFileName}
                </Button>
              )}
              {!coverLetterFileName && (
                <Button
                  id={`${ID_PREFIX}-cover-letter-button`}
                  data-pogr-id={`${ID_PREFIX}-cover-letter-button`}
                  variant="contained"
                  color="secondary"
                  size="medium"
                  component="label"
                  startIcon={
                    <>
                      <div className={styles.iconContainer}>
                        <img
                          src={"/static/upload.svg"}
                          alt="Upload cover letter icon"
                        />
                      </div>
                    </>
                  }
                  className={styles.resumeButton}
                >
                  {"Upload File"}
                  <input
                    id={`${ID_PREFIX}-cover-letter-file-input-hidden`}
                    data-pogr-id={`${ID_PREFIX}-cover-letter-file-input-hidden`}
                    type="file"
                    hidden
                    onChange={handleUploadCoverLetter}
                  />
                </Button>
              )}
            </div>
            <div
              id={`${ID_PREFIX}-send-button-container`}
              data-pogr-id={`${ID_PREFIX}-send-button-container`}
              className={styles.sendButtonContainer}
            >
              <Button
                id={`${ID_PREFIX}-send-button`}
                data-pogr-id={`${ID_PREFIX}-send-button`}
                variant="contained"
                color="secondary"
                size="medium"
                className={styles.sendButton}
                disabled
              >
                Send application
              </Button>
            </div>
          </form>
        </div>
      </div>
      <Divider className={styles.divider} />
    </Section>
  );
}

export default CareerApplySection;
