import React from "react";
import styles from "./PodcastHeroImage.module.scss";

type Props = {
  vidSrc?: string;
  imgSrc?: string;
};

const ID_PREFIX = "podcast-hero-image";

function PodcastHeroImage({ vidSrc, imgSrc }: Props) {
  return (
    <div
      id={`${ID_PREFIX}-container`}
      data-pogr-id={`${ID_PREFIX}-container`}
      className={styles.container}
    >
      <video
        id={`${ID_PREFIX}-video`}
        data-pogr-id={`${ID_PREFIX}-video`}
        className="videoTag"
        preload="auto"
        autoPlay
        controls={false}
        loop
        muted
        playsInline
        webkit-playsinline="true"
        poster="/static/hero-images/podcast-hero.webp"
      >
        <img
          id={`${ID_PREFIX}`}
          data-pogr-id={`${ID_PREFIX}`}
          src={"/static/hero-images/podcast-hero.webp"}
          alt="hero"
        />
      </video>
      <div
        id={`${ID_PREFIX}-top-gradient`}
        data-pogr-id={`${ID_PREFIX}-top-gradient`}
        className={styles.topGradient}
      />
      <div
        id={`${ID_PREFIX}-botom-gradient`}
        data-pogr-id={`${ID_PREFIX}-botom-gradient`}
        className={styles.bottomGradient}
      />
    </div>
  );
}

export default PodcastHeroImage;
