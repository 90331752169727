import React, { useState } from "react";
import styles from "./LanguageSection.module.scss";
import Section from "../../components/layouts/section/Section";
import SupportIcon from "../../modules/support-icon/SupportIcon";
import IntersectionObserverWrapper from "../../components/intersection-observer-wrapper/IntersectionObserverWrapper";
import { useSpring, config, animated } from "react-spring";

type Props = {};

const ID_PREFIX = "language-section";

function LanguageSection({}: Props) {
  const [isVisible, setIsVisible] = useState(false);
  const animTitle = useSpring({
    from: {
      opacity: 0,
      x: -400,
    },
    to: {
      opacity: isVisible ? 1 : 0,
      x: isVisible ? 0 : -400,
    },
    delay: 200,
    reset: isVisible ? false : true,
    config: config.default,
  });
  const animCplusplus = useSpring({
    from: {
      opacity: 0,
      scale: 0,
    },
    to: {
      opacity: isVisible ? 1 : 0,
      scale: isVisible ? 1 : 0,
    },
    delay: 500,
    reset: isVisible ? false : true,
    config: config.default,
  });
  const animCsharp = useSpring({
    from: {
      opacity: 0,
      scale: 0,
    },
    to: {
      opacity: isVisible ? 1 : 0,
      scale: isVisible ? 1 : 0,
    },
    delay: 600,
    reset: isVisible ? false : true,
    config: config.default,
  });
  const animGolang = useSpring({
    from: {
      opacity: 0,
      scale: 0,
    },
    to: {
      opacity: isVisible ? 1 : 0,
      scale: isVisible ? 1 : 0,
    },
    delay: 700,
    reset: isVisible ? false : true,
    config: config.default,
  });
  const animNodejs = useSpring({
    from: {
      opacity: 0,
      scale: 0,
    },
    to: {
      opacity: isVisible ? 1 : 0,
      scale: isVisible ? 1 : 0,
    },
    delay: 800,
    reset: isVisible ? false : true,
    config: config.default,
  });
  const animRust = useSpring({
    from: {
      opacity: 0,
      scale: 0,
    },
    to: {
      opacity: isVisible ? 1 : 0,
      scale: isVisible ? 1 : 0,
    },
    delay: 800,
    reset: isVisible ? false : true,
    config: config.default,
  });
  const handleChange = (visible: boolean) => {
    if (visible) {
      setIsVisible(true);
    }
  };
  return (
    <IntersectionObserverWrapper
      id={`${ID_PREFIX}-intersection-observer`}
      pogrId={`${ID_PREFIX}-intersection-observer`}
      onChange={handleChange}
    >
      <Section
        id={`${ID_PREFIX}-main`}
        pogrId={`${ID_PREFIX}-main`}
        style={{ backgroundColor: "#132439" }}
      >
        <div
          id={`${ID_PREFIX}-container`}
          data-pogr-id={`${ID_PREFIX}-container`}
          className={styles.container}
        >
          <animated.div
            id={`${ID_PREFIX}-title-container`}
            data-pogr-id={`${ID_PREFIX}-title-container`}
            style={animTitle}
          >
            <h1
              id={`${ID_PREFIX}-title`}
              data-pogr-id={`${ID_PREFIX}-title`}
              style={{ color: "#00DDDD" }}
            >
              And available in these languages!
            </h1>
          </animated.div>
          <div
            id={`${ID_PREFIX}-icons-container`}
            data-pogr-id={`${ID_PREFIX}-icons-container`}
            className={styles.iconsContainer}
          >
            <SupportIcon icon="cplusplus" name="C++" style={animCplusplus} />
            <SupportIcon icon="csharp" name="C#" style={animCsharp} />
            <SupportIcon icon="golang" name="GoLang" style={animGolang} />
            <SupportIcon icon="nodejs" name="Nodejs" style={animNodejs} />
            <SupportIcon icon="rust" name="Rust" style={animRust} />
          </div>
        </div>
        <div
          id={`${ID_PREFIX}-bottom-gradient`}
          data-pogr-id={`${ID_PREFIX}-bottom-gradient`}
          className={styles.bottomGradient}
        />
      </Section>
    </IntersectionObserverWrapper>
  );
}

export default LanguageSection;
