/**
 * // useWindowDimension.ts
 * * This hook returns the viewport/window height and width
 */

import { useEffect, useState } from 'react';

const BREAKPOINTS = [576, 996, 1600, 2600];

type WindowDimensions = {
  width: number | undefined;
  height: number | undefined;
  isMobile: boolean;
  isTablet: boolean;
};

const useWindowDimensions = (): WindowDimensions => {
  const [windowDimensions, setWindowDimensions] = useState<WindowDimensions>({
    width: undefined,
    height: undefined,
    isMobile: false,
    isTablet: false
  });

  const { width, height, isMobile, isTablet } = windowDimensions;

  useEffect(() => {
    function handleResize(): void {
      setWindowDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
        isMobile: window.innerWidth <= BREAKPOINTS[0],
        isTablet: window.innerWidth <= BREAKPOINTS[1],
      });
    }

    handleResize();
    window.addEventListener('resize', handleResize);
    return (): void => window.removeEventListener('resize', handleResize);
  }, []); // Empty array ensures that effect is only run on mount

  return {
    width,
    height,
    isMobile,
    isTablet
  };
};

export default useWindowDimensions;
