import { Button } from "@mui/material";
import styles from "./PremiumButton.module.scss";

type Props = {
  buttonId?: string;
  pogrId?: string;
  handleOnClick?: (e: any) => void;
};

const ID_PREFIX = "premium-button";

const PremiumButton = ({ buttonId, pogrId, handleOnClick }: Props) => {
  return (
    <Button
      id={`${ID_PREFIX}-${buttonId}`}
      data-pogr-id={`${ID_PREFIX}-${pogrId || buttonId}`}
      variant="contained"
      color="primary"
      size="large"
      endIcon={
        <div className={styles.iconContainer}>
          <img src={"/static/chevron-arrow-right.svg"} alt="Right arrow icon" />
        </div>
      }
      className={styles.container}
      onClick={handleOnClick}
    >
      Upgrade your account
    </Button>
  );
};

export default PremiumButton;
